import React, { useState, useEffect } from 'react'
import { Col, Form, Input, TreeSelect } from 'antd'
import { CustomSwitch } from './CustomSwitch'
import { api } from '../../services'
import TrainerSection from '../TrainerSection'
import { useTranslation } from 'react-i18next'

const { TreeNode } = TreeSelect

const MiddleSection = props => {
  const { t } = useTranslation()
  const [categories, setCategories] = useState([])
  const [isFetchingCategories, setIsFetchingCategories] = useState(false)
  const { getFieldDecorator, validateFields } = props.form

  const {
    onSwitch,
    isFreeItem,
    onCategory,
    onPrice,
    label,
    onVideoLink,
    category,
    price,
    videoLink
  } = props

  useEffect(() => {
    fetchCategories()
  }, [])
  useEffect(() => {
    validateFields()
  }, [validateFields])

  const fetchCategories = () => {
    setIsFetchingCategories(true)
    api.courses
      .fetchCategory()
      .then(res => {
        setCategories(res.data)
      })
      .catch(err => {
        console.log('Error in fetching categories')
      })
      .finally(() => setIsFetchingCategories(false))
  }

  const handleSwitch = () => {
    onSwitch(!isFreeItem)
  }

  const handleCategorySelect = id => {
    onCategory && onCategory(id)
  }

  const validatePrice = (rule, value, callback) => {
    if (value && value < 5) {
      callback(t('errors:price'))
    } else {
      callback()
    }
  }

  const onPriceChange = price => {
    if (price && price > 4) {
      onPrice && onPrice(price)
    }
  }

  return (
    <>
      <TrainerSection />
      <div className="mp-top_section_row">
        <Col md={11}>
          <Form.Item
            colon={false}
            required={false}
            label={t('labels:category')}
          >
            {getFieldDecorator('category_id', {
              rules: [{ required: true, message: t('v4:select_category') }],
              initialValue: category
            })(
              <TreeSelect
                size="large"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                onChange={handleCategorySelect}
                loading={isFetchingCategories}
              >
                {categories &&
                  categories.map(category => (
                    <TreeNode
                      value={category.id}
                      title={category.name}
                      key={category.id}
                    >
                      {category.children &&
                        category.children.map(option => (
                          <TreeNode
                            value={option.id}
                            title={option.name}
                            key={option.id}
                          />
                        ))}
                    </TreeNode>
                  ))}
              </TreeSelect>
            )}
          </Form.Item>
        </Col>
        <Col md={11}>
          <Form.Item
            colon={false}
            required={false}
            label={t('v4:promotional_video_link')}
          >
            {getFieldDecorator('video_link', {
              rules: [{ required: true, message: t('v4:input_video_link') }],
              initialValue: videoLink
            })(
              <Input
                size="large"
                maxLength={100}
                onChange={e => onVideoLink(e.target.value)}
              />
            )}
          </Form.Item>
        </Col>
      </div>
      <div className="mp-top_section_row">
        <Col md={11}>
          <div className="div_row">
            <Form.Item colon={false} required={false}>
              {getFieldDecorator('is_free', {
                initialValue: isFreeItem
              })(
                <CustomSwitch
                  label={` ${t('general:free')} ${label}`}
                  value={isFreeItem}
                  onChanged={handleSwitch}
                />
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              required={false}
              className="price_style"
              label={t('v4:price_dollor')}
            >
              {getFieldDecorator('price', {
                initialValue: price || 5,
                rules: [
                  {
                    validator: validatePrice
                  }
                ]
              })(
                <Input
                  size="large"
                  type="number"
                  min={5}
                  maxLength={100}
                  onChange={e => onPriceChange(e.target.value)}
                  disabled={isFreeItem}
                />
              )}
            </Form.Item>
          </div>
        </Col>
      </div>
    </>
  )
}

export default MiddleSection
