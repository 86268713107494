import React, { useState, useRef, useEffect } from 'react'
import { Icon, Switch, Tooltip, message, Popover, Modal, Button } from 'antd'
import { builder } from '../../../constants'
import nanoid from 'nanoid'
import './Toolbar.scss'
import className from 'classnames'
import { withTranslation } from 'react-i18next'
import customImages from './Images/index'
import { ChromePicker } from 'react-color'
import ImagePreview from '../../ImagePreview'

const { toolbar, types } = builder

const Toolbar = ({
  handleChangeBackground,
  handleData,
  pageStructure,
  mode,
  t,
  isLandScape,
  enableGrid,
  currentPage,
  pages,
  pages_landscape,
  handleLessonInput,
  onAutoBackgroundChange,
  lessonBckgrndImg,
  lessonBckgrndImgId,
  handleShowAnnotationImageModal,
  handleShowAnnotationTextModal,
  handleShowAnnotationVideoModal,
  handleShowAnnotationAudioModal,
  handleShowAnnotationModal,
  handleShowAudioModal,
  handleShowImageModal,
  handleShowVideoModal,
  handleShowTextModal,
  handleShowQuizModal,
  handleShowGrid
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [showColorModal, setShowColorModal] = useState(false)
  const [selectedColor, setSelectedColor] = useState(null)
  const [tempColor, setTempColor] = useState('#ffffff')

  useEffect(() => {
    const backgroundColor = pageStructure.find(
      node => node.type === types.BACKGROUND_COLOR
    )
    if (backgroundColor) {
      setSelectedColor(backgroundColor ? backgroundColor.params.content : null)
      setTempColor(backgroundColor ? backgroundColor.params.content : null)
    }
  }, [pageStructure])

  const handleColorChange = color => {
    if (color) {
      setTempColor(color.hex)
    }
  }

  const handleColorConfirm = () => {
    if (handleChangeBackground) {
      handleChangeBackground('color', tempColor)
      setSelectedColor(tempColor)
    }
    setShowColorModal(false)
  }

  const removeBackgroundElement = type => {
    if (handleData) {
      const data = pageStructure.filter(node => node.type !== type)
      handleData(data)
    }
    setShowImageModal(false)
    setShowColorModal(false)
  }

  // Background options popover content
  const OptionsContent = () => (
    <div className="background-options">
      <div
        className="background-option"
        onClick={() => {
          const hasBackgroundImage = pageStructure.find(
            node => node.type === types.BACKGROUND_IMAGE
          )
          if (hasBackgroundImage) {
            setShowImageModal(true)
          } else {
            handleChangeBackground('image')
          }
          setShowOptions(false)
        }}
      >
        <Icon type="picture" />
        <span>{t('v4:bg_image')}</span>
      </div>
      <div
        className="background-option"
        onClick={() => {
          setShowOptions(false)
          setShowColorModal(true)
        }}
      >
        <Icon type="bg-colors" />
        <span>{t('v4:bg_color')}</span>
      </div>
    </div>
  )

  const backgroundPageData = pageStructure.find(
    node =>
      (node.type === types.BACKGROUND_IMAGE ||
        node.type === types.BACKGROUND_COLOR) &&
      node.params && // Ensure params exists
      node.params.content
  )

  const backgroundElement = backgroundPageData || null

  console.log({ backgroundElement })
  const BackgroundIndicator = () => (
    <Tooltip title={t('v4:background')} placement="right">
      <div
        className="toolbar__item"
        onClick={() => {
          if (backgroundElement) {
            if (backgroundElement.type === types.BACKGROUND_IMAGE) {
              setShowImageModal(true)
            } else {
              setShowColorModal(true)
            }
          } else {
            setShowOptions(visible => !visible)
          }
        }}
      >
        {backgroundElement ? (
          <div className="toolbar__item-icon background-preview">
            {backgroundElement.type === types.BACKGROUND_IMAGE ? (
              <div
                className="image-preview"
                style={{
                  backgroundImage: `url(${backgroundElement.params.content})`,
                  borderRadius: '50%',
                  backgroundSize: 'cover'
                }}
              />
            ) : (
              <div
                className="color-preview"
                style={{
                  backgroundColor:
                    selectedColor || backgroundElement.params.content,
                  borderRadius: '50%'
                }}
              />
            )}
          </div>
        ) : (
          <div className="toolbar__item-icon">
            {getToolbarImage(types.BACKGROUND_IMAGE)}
          </div>
        )}
      </div>
    </Tooltip>
  )

  const handleBackgroundOptions = () => {
    return (
      <div className="toolbar__item-container">
        <Popover
          content={<OptionsContent />}
          visible={showOptions && !backgroundElement}
          onVisibleChange={visible => setShowOptions(visible)}
          trigger="click"
          placement="bottom"
        >
          <BackgroundIndicator />
        </Popover>

        {/* Image Modal */}
        <Modal
          title="Background Image Settings"
          visible={showImageModal}
          onCancel={() => setShowImageModal(false)}
          footer={[
            <Button
              key="remove"
              danger
              onClick={() => removeBackgroundElement(types.BACKGROUND_IMAGE)}
            >
              Remove Background Image
            </Button>,
            <Button
              key="change"
              type="primary"
              onClick={() => {
                handleChangeBackground('image')
                setShowImageModal(false)
              }}
            >
              {pageStructure.find(node => node.type === types.BACKGROUND_IMAGE)
                ? 'Change Image'
                : 'Add Image'}
            </Button>
          ]}
          centered
        >
          {pageStructure.find(node => node.type === types.BACKGROUND_IMAGE) && (
            <div className="background-image-preview">
              <h4>Current Background Image</h4>
              <ImagePreview
                src={
                  pageStructure.find(
                    node => node.type === types.BACKGROUND_IMAGE
                  ).params.content
                }
                style={{
                  width: '100%',
                  height: 200,
                  backgroundSize: 'cover',
                  borderRadius: 4,
                  marginBottom: 16
                }}
                className="image-preview-large"
              />
            </div>
          )}
        </Modal>

        {/* Color Modal */}
        <Modal
          title="Background Color Settings"
          visible={showColorModal}
          onCancel={() => setShowColorModal(false)}
          footer={[
            <Button
              key="remove"
              danger
              onClick={() => removeBackgroundElement(types.BACKGROUND_COLOR)}
            >
              Remove Background Color
            </Button>,
            <Button key="confirm" type="primary" onClick={handleColorConfirm}>
              Confirm Color
            </Button>
          ]}
          centered
        >
          {pageStructure.find(node => node.type === types.BACKGROUND_COLOR) && (
            <div className="current-color" style={{ marginBottom: '16px' }}>
              <h4>Current Background Color</h4>
              <div
                style={{
                  backgroundColor: pageStructure.find(
                    node => node.type === types.BACKGROUND_COLOR
                  ).params.content,
                  width: '100%',
                  height: 50,
                  borderRadius: 4
                }}
              />
            </div>
          )}
          <div className="color-picker-wrapper" style={{ padding: '16px' }}>
            <ChromePicker
              color={tempColor || '#fff'}
              onChange={handleColorChange}
              styles={{ default: { picker: { width: '100%' } } }}
            />
          </div>
        </Modal>
      </div>
    )
  }

  const addElement = type => {
    if (handleData) {
      const width = type === types.TEXT ? 450 : 400

      const data = {
        uid: nanoid(8),
        type: type,
        params: {
          content: '',
          width: width,
          height: 200,
          x: 0,
          y: 0
        }
      }

      handleData(data, true)
    }
  }

  const handleToolClick = ({ type }) => {
    const pageHasScenario = pageStructure.some(
      node => node.type === types.SCENARIO
    )

    if (pageHasScenario) {
      message.error(
        'You cannot add other element in a scenario page. Please try other pages or remove the scenario from the page first.',
        10
      )
      return
    }

    if (mode === types.ANNOTATION) {
      if (type === types.TEXT) {
        handleShowAnnotationTextModal()
      } else if (type === types.IMAGE) {
        handleShowAnnotationImageModal()
      } else if (type === types.VIDEO) {
        handleShowAnnotationVideoModal()
      } else if (type === types.AUDIO) {
        handleShowAnnotationAudioModal()
      }
    } else {
      if (type === types.AUDIO) {
        handleShowAudioModal()
      } else if (type === types.IMAGE) {
        handleShowImageModal(false)
      } else if (type === types.AR_IMAGE) {
        handleShowImageModal(true)
      } else if (type === types.VIDEO) {
        handleShowVideoModal()
      } else if (type === types.TEXT) {
        handleShowTextModal()
      } else if (type === types.ANNOTATION) {
        handleShowAnnotationModal()
      } else if (type === types.QUIZ) {
        handleShowQuizModal()
      } else if (type === types.GRID) {
        handleShowGrid()
      } else {
        addElement(type)
      }
    }
  }

  const onChangeAutoBgChange = () => {
    const pagesTemp = [...pages]
    const pagesLandscapeTemp = [...pages_landscape]
    const pageStructureTemp = [...pageStructure]
    const indexVal = pageStructure.findIndex(
      item => item.type === 'BACKGROUND_IMAGE_AUTO'
    )

    if (lessonBckgrndImg !== null && indexVal === -1) {
      const dataLND = {
        uid: nanoid(8),
        type: 'BACKGROUND_IMAGE_AUTO',
        auto: true,
        params: { content: lessonBckgrndImg, content_id: lessonBckgrndImgId }
      }
      pagesTemp[currentPage - 1].data.push(dataLND)
      pagesLandscapeTemp[currentPage - 1].data.push(dataLND)
      handleLessonInput('pages', pagesTemp)
      handleLessonInput('pages_landscape', pagesLandscapeTemp)
      onAutoBackgroundChange()
    }

    if (
      indexVal !== -1 &&
      pageStructure.length > 0 &&
      pageStructure[indexVal].hasOwnProperty('auto')
    ) {
      if (pageStructure[indexVal].auto === false) {
        pageStructureTemp[indexVal].auto = true
      } else {
        pageStructureTemp[indexVal].auto = false
      }
      pagesTemp[currentPage - 1].data = pageStructureTemp
      pagesLandscapeTemp[currentPage - 1].data = pageStructureTemp
      handleLessonInput('pages', pagesTemp)
      handleLessonInput('pages_landscape', pagesLandscapeTemp)
      onAutoBackgroundChange()
    }
  }

  const getToolbarImage = type => {
    switch (type) {
      case types.ANNOTATION:
        return <img src={customImages.Annotation} alt="Annotation" />
      case types.AR_IMAGE:
        return <img src={customImages.ArImage} alt="AR" />
      case types.IMAGE:
        return <img src={customImages.Image} alt="Image" />
      case types.AUDIO:
        return <img src={customImages.Audio} alt="Audio" />
      case types.BACKGROUND_IMAGE:
        return <img src={customImages.Background} alt="Background" />
      case types.GRID:
        return <img src={customImages.Grid} alt="Grid" />
      case types.QUIZ:
        return <img src={customImages.Quiz} alt="Quiz" />
      case types.TEXT:
        return <img src={customImages.Text} alt="Text" />
      case types.VIDEO:
        return <img src={customImages.Video} alt="Video" />
      default:
        return null
    }
  }

  const items = toolbar.filter(item => item.mode.includes(mode))
  const classNames = className('toolbar__list', {
    toolbar__list__annotation: mode === types.ANNOTATION
  })

  const indexVal = pageStructure.findIndex(
    item => item.type === 'BACKGROUND_IMAGE_AUTO' && item.auto === true
  )

  console.log({
    selectedColor,
    tempColor
  })

  return (
    <div className={mode === types.ANNOTATION ? '' : 'toolbar'}>
      <div className="toolbar__inner">
        <ul className={classNames}>
          {items.map((item, index) => {
            if (item.type === types.BACKGROUND_IMAGE) {
              return <li key={index}>{handleBackgroundOptions()}</li>
            }
            return (
              <li key={index}>
                {!isLandScape && (
                  <Tooltip title={item.name}>
                    <div
                      className={`toolbar__item ${
                        enableGrid && item.type === 'GRID' ? 'active_grid' : ''
                      }`}
                      onClick={() => handleToolClick(item)}
                    >
                      <div className="toolbar__item-icon">
                        {getToolbarImage(item.type) || (
                          <Icon type={item.icon} />
                        )}
                      </div>
                      <div className="toolbar__item-text">{item.name}</div>
                    </div>
                  </Tooltip>
                )}
              </li>
            )
          })}
        </ul>
        {!pageStructure.find(node => node.type === types.BACKGROUND_IMAGE) && (
          <div className="toolbar__autobg">
            <p>{t('auto_background')}</p>
            <Switch
              checked={
                !pageStructure.find(
                  node => node.type === types.BACKGROUND_IMAGE
                ) &&
                pageStructure.length > 0 &&
                indexVal !== -1 &&
                pageStructure[indexVal].hasOwnProperty('auto') &&
                pageStructure[indexVal].auto === true
              }
              onChange={onChangeAutoBgChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default withTranslation('v2')(Toolbar)
