import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import className from 'classnames'
import { Button, Modal, Pagination, Tabs, Row, Col, Icon } from 'antd'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Page } from './styled'
import './Library.scss'
import { bytesToSize, getFileType, getFileExtension } from '../../../../utils'
import { Card, AddMediaDetail, UpdateStatusModal } from '../../components'
import Spinner from '../../../../components/Spinner/Spinner'
import { Search, Filter } from '../../../../components'
import { general } from '../../../../constants'
import history from '../../../../history'
import notification from '../../components/notification'
import { uploadMedia } from './images'
import { updateMedia, removeMedias } from '../../../../services/api/library'
import { mazeProvider, getAvatar } from '../../../../services/api/maze'
import PopupMediaFilter from '../../components/MediaFilter/PopupMediaFilter'
import { EditImageContainer } from '../../../../components/TextOnImage'
import { deactiveImageIcon } from '../../../Courses/Create/nestedComponents/PageBuilder/Components/Image/images'

const filterData = [
  { value: 'NEWEST', text: 'filters:Newest_to_Oldest' },
  { value: 'OLDEST', text: 'filters:Oldest_to_Newest' },
  { value: 'ASC', text: 'filters:A_Z' },
  { value: 'DESC', text: 'filters:Z_A' }
]
class PopupMedia extends Component {
  state = {
    page: 1, // this variable use for my media for current page to get api
    pageSize: 20, // this variable use for my media for page size in pagination
    pagePublic: 1, // this variable use for public media pagination for current page to get api
    pageSizePublic: 20, // this variable user for public media  for page size in pagination
    pageOrg: 1, // this variable use for org media pagination for current page to get api
    pageSizeOrg: 20, // this variable user for org media  for page size in pagination
    search: '',
    searchPublic: '',
    searchOrg: '',
    myMediaType:
      this.props.types === 'images'
        ? ['IMAGE', 'GIF']
        : this.props.types === 'videos'
          ? 'VIDEO'
          : this.props.types === 'pdf'
            ? 'APPLICATION'
            : this.props.types === 'AR'
              ? 'ARIMAGE'
              : [],
    publicLibraryType:
      this.props.types === 'images'
        ? ['IMAGE', 'GIF']
        : this.props.types === 'videos'
          ? 'VIDEO'
          : this.props.types === 'pdf'
            ? 'APPLICATION'
            : this.props.types === 'AR'
              ? 'ARIMAGE'
              : [],
    orgMediaType:
      this.props.types === 'images'
        ? ['IMAGE', 'GIF']
        : this.props.types === 'videos'
          ? 'VIDEO'
          : this.props.types === 'pdf'
            ? 'APPLICATION'
            : this.props.types === 'AR'
              ? 'ARIMAGE'
              : [],
    modal: false,
    publicLibrary: {},
    data: {},
    orgLibrary: {},
    tab: general.MY_MEDIA,
    isFetching: true,
    publicMediaLoaded: false,
    orgMediaLoaded: false,
    files: [],
    fileNotCompatible: false,
    mazescenes: null,
    showStatusModal: false,
    mediaNoTopics: [],
    loadingModalButtion: false,
    isHideFilter: false,
    isRemovingMedia: false,
    showDeleteModal: false,
    isFetchingMazeScenes: true,
    mediaType:
      this.props.types === 'images'
        ? [
          {
            label: this.props.t('media:image'),
            value: general.IMAGE,
            selected: true
          },
          {
            label: this.props.t('media:gif'),
            value: general.GIF,
            selected: true
          }
        ]
        : this.props.types === 'pdf'
          ? [
            {
              label: this.props.t('v4:pdf'),
              value: general.PDF,
              selected: true
            }
          ]
          : this.props.types === 'AR'
            ? [
              {
                label: this.props.t('v2:ar_assets'),
                value: general.ARIMAGE,
                selected: true
              }
            ]
            : this.props.types === 'videos'
              ? [
                {
                  label: this.props.t('media:video'),
                  value: general.VIDEO,
                  selected: true
                }
              ]
              : [],
    sortBy: 'NEWEST',
    privateFiles: {},
    publicFiles: {},
    orgFiles: {},
    loading: false
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  handleGetLibraryList = async () => {
    const { getLibraryList, selectedFiles, isGame = false } = this.props
    const { page, pageSize, search, myMediaType, sortBy } = this.state

    await getLibraryList({
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      search,
      sort: sortBy,
      type: this.getType(myMediaType),
      isGame
    })

    const { data } = this.props.library
    const newArray =
      data.rows &&
      data.rows.map(value => {
        value.isSelected = !!(selectedFiles && selectedFiles.includes(value.id))
        return value
      })
    const objPrivateData = { count: newArray.length, rows: newArray }
    this.setState({
      data,
      privateFiles: objPrivateData,
      isFetching: false
    })
  }

  handleGetPublicMedia = async () => {
    const { getPublicList, selectedFiles } = this.props
    const {
      pageSizePublic,
      pagePublic,
      searchPublic,
      publicLibraryType,
      sortBy
    } = this.state

    await getPublicList({
      offset: this.createOffset(pagePublic, pageSizePublic),
      limit: pageSizePublic,
      search: searchPublic,
      type: this.getType(publicLibraryType),
      sort: sortBy,
      isPublic: true,
      publicMediaLoaded: false
    })

    const { publicLibrary } = this.props.library
    const newArray =
      publicLibrary.rows &&
      publicLibrary.rows.map(value => {
        value.isSelected = !!(selectedFiles && selectedFiles.includes(value.id))
        return value
      })
    const objPublicData = { count: newArray.length, rows: newArray }

    this.setState({
      publicLibrary,
      publicFiles: objPublicData,
      isFetching: false
    })
  }

  handleGetOrgList = async () => {
    const { getOrgList, selectedFiles } = this.props
    const { pageOrg, pageSizeOrg, searchOrg, orgMediaType, sortBy } = this.state

    await getOrgList({
      offset: this.createOffset(pageOrg, pageSizeOrg),
      limit: pageSizeOrg,
      search: searchOrg,
      sort: sortBy,
      type: this.getType(orgMediaType),
      isOrg: true
    })

    const { orgLibrary } = this.props.library
    const newArray =
      orgLibrary.rows &&
      orgLibrary.rows.map(value => {
        value.isSelected = !!(selectedFiles && selectedFiles.includes(value.id))
        return value
      })
    const objOrgData = { count: newArray.length, rows: newArray }

    this.setState({
      orgLibrary,
      orgFiles: objOrgData,
      isFetching: false
    })
  }

  getType = data => {
    if (_.isArray(data)) return data.join(',')
    return `${data}`
  }

  handleGetMazeScenes = () => {
    this.setState({ isFetchingMazeScenes: true }, () => {
      mazeProvider({
        systemRequest: true,
        action: 'list_scene'
      }).then(res => {
        console.log('list scenes: ', res)
        const mazeObject = {
          count: res.data.scene.length,
          rows: res.data.scene
        }
        this.setState({
          data: mazeObject,
          mazescenes: mazeObject,
          isFetchingMazeScenes: false
        })
      })
    })
  }

  handleSelectAvatar = () => {
    const { genderType } = this.props
    console.log("genderType: ", genderType)
    this.setState({ isFetchingMazeScenes: true }, () => {
      getAvatar()
        .then(res => {
          console.log('Response Avatar list', res);
          const filteredSortedData = genderType
            ? res.data.data.filter(item => item.avatar_gender === genderType)
            : res.data.data;

          const sortedData = filteredSortedData.sort((a, b) => a.id - b.id);

          console.log(sortedData);
          const mazeObject = {
            count: sortedData.length,
            rows: sortedData
          };

          this.setState({
            data: mazeObject,
            mazescenes: mazeObject,
            isFetchingMazeScenes: false
          });
        })
        .catch(error => {
          console.error('Error fetching avatars:', error);
          this.setState({ isFetchingMazeScenes: false });
        });
    })
  }

  getLibrary = async () => {
    // Get My Library list
    const {
      library: { isFetching },
      isMazeCreate,
      isGame
    } = this.props
    if (isMazeCreate === 'scene') {
      this.handleGetMazeScenes()
    } else if (isMazeCreate === 'avatar') {
      this.handleSelectAvatar()
    } else {
      await this.handleGetLibraryList()
      if (isGame) return
      await this.handleGetPublicMedia()
      await this.handleGetOrgList()
    }

    this.setState({
      isFetching
    })
  }

  componentDidMount() {
    this.getLibrary()
  }

  showModal = () => {
    const { clearUploadList } = this.props

    clearUploadList()

    this.setState({
      modal: true
    })
  }

  hideModal = async () => {
    const { clearUploadList } = this.props

    this.setState({
      modal: false,
      isFetching: true
    })

    // Refrest data base on current tab
    await this.handleGetLibraryList()

    this.setState({
      isFetching: false
    })

    clearUploadList()

    setTimeout(() => {
      this.setState({
        files: []
      })
    }, 100)
  }

  onDrop = dropped => {
    const { upload } = this.props
    const { files } = this.state
    const newFiles = files.concat(dropped)
    this.setState({
      files: newFiles
    })

    if (dropped.length === 0) {
      this.setState({ fileNotCompatible: true })
    }

    return dropped.forEach(file => upload(file))
  }

  handleCancelUpload = mediaItem => {
    console.log('on cancle', mediaItem)
    if (mediaItem.source) {
      // cancel the request (the message parameter is optional)
      mediaItem.source.cancel()
    }
  }

  handleRemoveMedia = async (mediaItem, ind) => {
    this.setState({ isRemovingMedia: true })
    const data = {
      mediaIds: [mediaItem]
    }
    try {
      await removeMedias(data)
      const { files } = this.state
      files[ind].removed = true

      this.setState({
        isRemovingMedia: false,
        files
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
        isFetching: true
      },
      this.getLibrary
    )
  }

  onChangePagePublic = (pagePublic, pageSizePublic) => {
    this.setState(
      {
        pagePublic,
        pageSizePublic,
        isFetching: true
      },
      this.handleGetPublicMedia
    )
  }

  onChangePageOrg = (pageOrg, pageSizeOrg) => {
    this.setState(
      {
        pageOrg,
        pageSizeOrg,
        isFetching: true
      },
      this.handleGetOrgList
    )
  }

  /**
   * Allow user to update their media library status
   * @singleUpdate
   * @param
   * item = media that have to update
   * ind = index of media
   */
  handleUpdateLibraryStatus = async (item, thumb, ind) => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    const remarkPublic = item.remark_public
    const remarkOrg = item.remark_org
    const hasKeywords = item.keywords !== null && item.keywords.length > 0
    const hasTopics = item.topics.length > 0

    let action
    if (isSuperAdmin) {
      if (remarkPublic) {
        action = general.PRIVATE
      } else {
        action = general.PUBLIC
      }
    } else {
      if (remarkOrg) {
        action = general.PRIVATE
      } else {
        action = general.ORG
      }
    }

    console.log('action: ', action)
    const notPrivateAction = action === general.PUBLIC || action === general.ORG

    // Open Update Media status if media no topic
    if (notPrivateAction && (!hasTopics || !hasKeywords)) {
      const { handleMakeType } = this.props
      const status = action
      item.thumb = thumb
      this.setState(
        {
          showStatusModal: true,
          mediaNoTopics: [item]
        },
        () => {
          handleMakeType(status)
        }
      )
      return
    }

    const { updateLibraryStatus, updateOrgLib, user } = this.props
    // if (isPageBuilder) {
    //   return
    // }

    const { data } = this.state
    let query = { action, library: [item.id] }
    this.setState({ isFetching: true })
    let setting = false
    if (action === general.PRIVATE) {
      // Update media library status
      if (remarkOrg) {
        await updateOrgLib(query)
      } else {
        await updateLibraryStatus(query)
      }

      // getLibrary to refresh data
      await this.getLibrary()
      this.setState(
        {
          isFetching: false,
          publicMediaLoaded: false
        },
        () => {
          notification(action)
        }
      )
    } else if (action === general.ORG) {
      // Update media library status
      // query = { action, organization_id: user.info.active_organization_id, library: [item.id] }
      const mediaHasTopics = item.topics.length > 0
      const mediaHasKeywords =
        item.keywords !== null && item.keywords.length > 0

      if (mediaHasTopics && mediaHasKeywords) {
        query.organization_id = user.info.active_organization_id
        await updateOrgLib(query)
        setting = true
      }
      if (setting) {
        // getLibrary to refresh data
        await this.getLibrary()
        this.setState(
          {
            isFetching: false,
            orgMediaLoaded: false
          },
          () => {
            notification(action)
          }
        )
      }
    } else {
      // Show loading for Make Private button
      data.rows[ind].is_loading = true
      this.setState({ data })

      // // Update media library status
      // await updateLibraryStatus(query)

      // // getLibrary to refresh data
      // await this.getLibrary()
      // this.setState(
      //   {
      //     isFetching: false,
      //     publicMediaLoaded: false
      //   },
      //   () => {
      //     notification(action)
      //   }
      // )
      const mediaHasTopics = item.topics.length > 0
      const mediaHasKeywords =
        item.keywords !== null && item.keywords.length > 0

      if (mediaHasTopics && mediaHasKeywords) {
        await updateLibraryStatus(query)
        setting = true
      }
      if (setting) {
        // getLibrary to refresh data
        await this.getLibrary()
        this.setState(
          {
            isFetching: false,
            publicMediaLoaded: false
          },
          () => {
            notification(action)
          }
        )
      }
    }
  }

  /**
   * Mark as Card selected or not selected
   */
  handleCheckCard = (value, thumb, ind) => {
    const { isPageBuilder } = this.props
    const { data } = this.state

    /**
     * not allow user to select if user stay on Page builder page
     */
    if (isPageBuilder) {
      return
    }

    data.rows[ind].is_checked = value
    data.rows[ind].thumb = thumb

    this.handleHideFilter(data.rows)

    this.setState({
      data
    })
  }

  handleHideFilter = data => {
    const countSelectData = _.filter(data, item => {
      return item.is_checked
    })

    let isHideFilter = false
    if (countSelectData.length > 0) {
      isHideFilter = true
    }
    this.setState({ isHideFilter })
  }

  checkMediaHasTopic = mediaList => {
    /**
     * Filter out Media that no Topic and has checked
     */
    const mediaNoTopics = _.filter(mediaList, item => {
      return item.is_checked && item.topics.length <= 0
    })

    this.setState({ mediaNoTopics })

    return !(mediaNoTopics.length > 0)
  }

  checkMediaHasKeywords = mediaList => {
    /**
     * Filter out Media that no Keywords and has checked
     */
    const mediaNoKeywords = _.filter(mediaList, item => {
      return item.is_checked && item.keywords && item.keywords.length <= 0
    })

    this.setState({ mediaNoKeywords })

    return !(mediaNoKeywords.length > 0)
  }

  makeMediaStatus = async (status, medias) => {
    const { updateOrgLib, user } = this.props
    this.setState({ loadingModalButtion: true })
    const updateList = []

    if (status === general.ORG) {
      for (const item of medias) {
        const keywords =
          Array.isArray(item.keywords) && item.keywords.length > 0
            ? item.keywords.join(',')
            : item.keywords
        const query = {
          action: status,
          organization_id: user.info.active_organization_id,
          library: [item.id],
          topics: item.topic_ids,
          keywords
        }
        const data = { topics: item.topic_ids, keywords }
        updateList.push(updateOrgLib(query))
        updateList.push(updateMedia(item.id, data))
      }
    } else {
      for (const item of medias) {
        const keywords =
          Array.isArray(item.keywords) && item.keywords.length > 0
            ? item.keywords.join(',')
            : item.keywords
        const data = { status, topics: item.topic_ids, keywords }
        updateList.push(updateMedia(item.id, data))
      }
    }

    await Promise.all(updateList)

    this.setState(
      {
        showStatusModal: false,
        loadingModalButtion: false,
        isFetching: true,
        isHideFilter: false
      },
      () => {
        notification(status)
        this.props.handleMakeType(null)
      }
    )

    await this.getLibrary()
    await this.handleGetPublicMedia()
    await this.handleGetOrgList()

    this.setState({
      isFetching: false
    })
  }

  /**
   * Allow user to update their media library status
   * @multipleUpdate
   * @param: is_public = true (Update Public to private)
   */
  updateMediaStatus = async status => {
    const {
      updateLibraryStatus,
      updateOrgLib,
      user,
      handleMakeType
    } = this.props
    const {
      user: {
        info: { isSuperAdmin, isAdmin }
      }
    } = this.props
    const { data, publicLibrary, orgLibrary, tab } = this.state
    let activeTabData
    if (tab === general.PUBLIC_MEDIA) {
      activeTabData = publicLibrary
    } else if (tab === general.ORGANIZATION_LIBRARY) {
      activeTabData = orgLibrary
    } else {
      activeTabData = data
    }

    const mediaSelected = _.filter(activeTabData.rows, item => {
      return item.is_checked
    })
    const notPrivateStatus = status === general.PUBLIC || status === general.ORG
    const mediaHasTopics = _.filter(mediaSelected, item => {
      return item.topics.length > 0
    })

    const mediaHasKeywords = _.filter(mediaSelected, item => {
      return item.keywords !== null && item.keywords.length > 0
    })

    if (
      notPrivateStatus &&
      (mediaHasTopics.length !== mediaSelected.length ||
        mediaHasKeywords.length !== mediaSelected.length)
    ) {
      this.setState(
        {
          showStatusModal: true,
          mediaNoTopics: mediaSelected
        },
        () => {
          handleMakeType(status)
        }
      )
    } else {
      this.setState({
        showStatusModal: false
      })
    }

    const rows = activeTabData.rows
    // Filter and map library for get library ID
    const library = _.map(
      _.filter(rows, item => {
        return item.is_checked
      }),
      'id'
    )
    let query = {
      action: status,
      library
    }
    // Show loading
    let libraryOrg = []
    _.filter(mediaSelected, item => {
      if (item.remark_org) {
        libraryOrg.push(item.id)
      }
    })
    let libraryPub = []
    _.filter(mediaSelected, item => {
      if (item.remark_public) {
        libraryPub.push(item.id)
      }
    })

    if (status === general.PRIVATE) {
      try {
        this.setState({ isFetching: true })
        if ((isSuperAdmin || isAdmin) && libraryOrg.length > 0) {
          const query = { action: status, library: libraryOrg }
          await updateOrgLib(query)
        }
        if (isSuperAdmin && libraryPub.length > 0) {
          const query = { action: status, library: libraryPub }
          await updateLibraryStatus(query)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        // getLibrary to refresh data
        await this.getLibrary()
        this.setState(
          {
            isFetching: false,
            publicMediaLoaded: false
          },
          () => {
            notification(status)
          }
        )
      }
    } else if (status === general.ORG) {
      let setting = false
      try {
        this.setState({ isFetching: true })
        const mediaHasTopics = _.filter(mediaSelected, item => {
          return item.topics.length > 0
        })

        const mediaHasKeywords = _.filter(mediaSelected, item => {
          return item.keywords !== null && item.keywords.length > 0
        })
        if (
          mediaHasTopics.length === mediaSelected.length &&
          mediaHasKeywords.length === mediaSelected.length
        ) {
          query.organization_id = user.info.active_organization_id
          await updateOrgLib(query)
          setting = true
        }
        if (setting) {
          await this.getLibrary()
          this.setState(
            {
              isFetching: false,
              orgMediaLoaded: false
            },
            () => {
              notification(status)
            }
          )
        }
      } catch (error) {
        console.log('error', error)
      }
    } else {
      this.setState({ isFetching: true })
      let setting = false
      try {
        // this.setState({ data })
        // await updateLibraryStatus(query)
        const mediaHasTopics = _.filter(mediaSelected, item => {
          return item.topics.length > 0
        })

        const mediaHasKeywords = _.filter(mediaSelected, item => {
          return item.keywords !== null && item.keywords.length > 0
        })
        if (
          mediaHasTopics.length === mediaSelected.length &&
          mediaHasKeywords.length === mediaSelected.length
        ) {
          this.setState({ data })
          await updateLibraryStatus(query)
          setting = true
        }
        if (setting) {
          // getLibrary to refresh data
          await this.getLibrary()
          this.setState(
            {
              isFetching: false,
              publicMediaLoaded: false
            },
            () => {
              notification(status)
            }
          )
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  deleteMedia = () => {
    const { data } = this.state
    const { t } = this.props
    const status = ''
    const type = 'success'
    const title = t('media:delete_success')
    const width = 400
    let library = []

    this.setState({
      isFetching: true,
      showDeleteModal: false,
      isHideFilter: false
    })

    if (!_.isEmpty(data.rows)) {
      library = _.map(
        _.filter(data.rows, item => {
          return item.is_checked
        }),
        'id'
      )
    }
    const medias = {
      mediaIds: library
    }
    removeMedias(medias).then(() => {
      this.handleGetLibraryList()
      notification(status, type, title, width)
    })
  }

  onHandleSearch = (value, type) => {
    if (type === general.MY_MEDIA) {
      this.setState(
        {
          search: value,
          page: 1,
          isFetching: true
        },
        () => {
          this.getLibrary()
        }
      )
    } else if (type === general.PUBLIC_MEDIA) {
      this.setState(
        {
          searchPublic: value,
          pagePublic: 1,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    } else {
      this.setState(
        {
          searchOrg: value,
          pageOrg: 1,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  onSearch = _.debounce((value, type) => this.onHandleSearch(value, type), 1000)

  getPage = () =>
    this.state.tab === general.PUBLIC_MEDIA
      ? this.state.pagePublic
      : this.state.page

  handleChangeTabs = async tab => {
    const { publicMediaLoaded, orgMediaLoaded } = this.state
    this.setState({
      tab // get type of tab when user click change
    })
    if (tab === general.PUBLIC_MEDIA && !publicMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetPublicMedia()
      this.setState({
        publicMediaLoaded: true,
        isFetching: false
      })
    } else if (tab === general.ORGANIZATION_LIBRARY && !orgMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetOrgList()
      this.setState({
        orgMediaLoaded: true,
        isFetching: false
      })
    }
  }

  handleMultipleFileSelect = (item, checked) => {
    let files = {}
    const {
      privateFiles: { rows, count }
    } = this.state
    let newData = []
    if (checked) {
      newData =
        rows &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = true
          }
          return value
        })
    } else {
      newData =
        rows &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = false
          }
          return value
        })
    }
    files = { count: count, rows: newData }

    this.setState({ privateFiles: files })
    return files
  }
  handleMultiplePublicFileSelect = (item, checked) => {
    let files = {}
    const {
      publicFiles: { rows, count }
    } = this.state
    let newData = []
    if (checked) {
      newData =
        rows &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = true
          }
          return value
        })
    } else {
      newData =
        rows &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = false
          }
          return value
        })
    }
    files = { count: count, rows: newData }

    this.setState({ publicFiles: files })
    return files
  }
  handleMultipleOrgFileSelect = (item, checked) => {
    let files = {}
    const {
      orgFiles: { rows, count }
    } = this.state
    let newData = []
    if (checked) {
      newData =
        rows &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = true
          }
          return value
        })
    } else {
      newData =
        rows &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = false
          }
          return value
        })
    }
    files = { count: count, rows: newData }

    this.setState({ orgFiles: files })
    return files
  }

  /**
   *  data is an object contain media private media list and count
   *  rows is an array of private media list
   */
  handlePrivateFileSelect = item => {
    const { data } = this.state
    console.log(this.state)
    if (data) {
      const { rows } = data
      const newRows =
        rows.length > 0
          ? rows.map(value => {
            if (value.id === item.id) {
              value.isSelected = true
            } else {
              value.isSelected = false
            }
            return value
          })
          : []

      let newData
      if (data.count && newRows) {
        newData = {
          count: data.count,
          rows: newRows
        }
        return newData
      } else {
        return null
      }
    }
  }

  handlePublicFileSelect = item => {
    const { publicLibrary } = this.state
    if (publicLibrary) {
      const { rows } = publicLibrary
      const newRows =
        rows &&
        rows.length > 0 &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = !value.isSelected
          } else {
            value.isSelected = false
          }
          return value
        })

      let newData
      if (publicLibrary.count && newRows) {
        newData = {
          count: publicLibrary.count,
          rows: newRows
        }
        return newData
      } else {
        return null
      }
    }
  }

  handleOrgFileSelect = item => {
    const { orgLibrary } = this.state
    if (orgLibrary) {
      const { rows } = orgLibrary
      const newRows =
        rows &&
        rows.length > 0 &&
        rows.map(value => {
          if (value.id === item.id) {
            value.isSelected = !value.isSelected
          } else {
            value.isSelected = false
          }
          return value
        })

      let newData
      if (orgLibrary.count && newRows) {
        newData = {
          count: orgLibrary.count,
          rows: newRows
        }
        return newData
      } else {
        return null
      }
    }
  }

  handleMultipleFileDetails = (item, e) => {
    const { isPageBuilder } = this.props
    const { checked } = e.target
    if (isPageBuilder) {
      const newPrivateMediaData = this.handleMultipleFileSelect(item, checked)
      const newPublicMediaData = this.handleMultiplePublicFileSelect(
        item,
        checked
      )
      const newOrgMediaData = this.handleMultipleOrgFileSelect(item, checked)

      this.setState({
        privateFiles: newPrivateMediaData || null,
        publicFiles: newPublicMediaData || null,
        orgFiles: newOrgMediaData || null
      })
    }
  }

  addSelected = () => {
    const { handleInsertFile } = this.props
    const { privateFiles, publicFiles, orgFiles } = this.state
    handleInsertFile(privateFiles, publicFiles, orgFiles)
  }

  clearSelected = () => {
    const { handleRemoveSelected } = this.props
    this.setState(state => ({
      privateFiles: {
        ...state.privateFiles,
        rows:
          state.privateFiles.rows &&
          state.privateFiles.rows.map(p => {
            if (p.isSelected) {
              p.isSelected = false
            }
            return p
          })
      },
      publicFiles: {
        ...state.publicFiles,
        rows:
          state.publicFiles.rows &&
          state.publicFiles.rows.map(p => {
            if (p.isSelected) {
              p.isSelected = false
            }
            return p
          })
      },
      orgFiles: {
        ...state.orgFiles,
        rows:
          state.orgFiles.rows &&
          state.orgFiles.rows.map(p => {
            if (p.isSelected) {
              p.isSelected = false
            }
            return p
          })
      }
    }))
    handleRemoveSelected()
  }
  /**
   * publicLibrary is an object contain public media list and count
   * there will a rows in publicLabrary like this.state.data is private library
   */
  handleViewDetails = item => {
    const { isPageBuilder, handleInsertData } = this.props

    if (isPageBuilder) {
      const newPrivateMediaData = this.handlePrivateFileSelect(item)
      const newPublicMediaData = this.handlePublicFileSelect(item)
      const newOrgMediaData = this.handleOrgFileSelect(item)
      this.setState(
        {
          data: newPrivateMediaData || null,
          publicLibrary: newPublicMediaData || null,
          orgLibrary: newOrgMediaData || null
        },
        () => {
          handleInsertData(
            newPrivateMediaData,
            newPublicMediaData,
            newOrgMediaData
          )
        }
      )
    } else {
      if (this.state.tab === general.ORGANIZATION_LIBRARY) {
        const params = {
          page: this.getPage(),
          isOrg: this.state.tab === general.ORGANIZATION_LIBRARY,
          search: this.getSearch(),
          type: this.getFilterType(),
          sort: this.getSortType()
        }
        const query = queryString.stringify(params)
        history.push(`/library/gallery/${item.id}?${query}`, params)
      } else {
        const params = {
          page: this.getPage(),
          isPublic: this.state.tab === general.PUBLIC_MEDIA,
          search: this.getSearch(),
          type: this.getFilterType(),
          sort: this.getSortType()
        }
        const query = queryString.stringify(params)
        history.push(`/library/gallery/${item.id}?${query}`, params)
      }
    }
  }

  getSearch = () =>
    this.state.tab === general.PUBLIC_MEDIA
      ? this.state.searchPublic
      : this.state.search

  getFilterType = () =>
    this.state.tab === general.PUBLIC_MEDIA
      ? this.state.publicLibraryType
      : this.state.myMediaType

  cancelError = () => {
    this.setState({ fileNotCompatible: false })
  }

  handleCloseStatusModal = () => {
    this.setState(
      {
        showStatusModal: false
      },
      () => {
        this.props.handleMakeType(null)
      }
    )
  }

  /**
   * Handle user filer by media type
   */
  hangeFilterChange = async (type = null, tab) => {
    // Filter for My Media tab
    this.setState({ isFetching: true })

    if (tab === general.MY_MEDIA) {
      this.setState(
        {
          myMediaType: type,
          page: 1,
          isFetching: true,
          selectedFiles: {}
        },
        () => {
          this.getLibrary()
        }
      )
    }

    // Filter for Public Library tab
    if (tab === general.PUBLIC_MEDIA) {
      this.setState(
        {
          publicLibraryType: type,
          pagePublic: 1,
          isFetching: true,
          selectedFiles: {}
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    }

    // Filter for Org Library tab
    if (tab === general.ORGANIZATION_LIBRARY) {
      this.setState(
        {
          orgMediaType: type,
          pageOrg: 1,
          isFetching: true,
          selectedFiles: {}
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  uploadListClassName = list => {
    return list.length <= 0 ? 'upload-modal__list-wrapper__no-border' : ''
  }

  searchContainer = type => {
    const { t } = this.props
    return (
      <div className="controls head search__container">
        <div className="controls__part">
          <div className="controls__item">
            <Search
              className="search"
              placeholder={t('placeholders:search_media')}
              onChange={e => this.onSearch(e.target.value, type)}
            />
          </div>
          {/* <div className='controls__item'>
            <Button type='primary' onClick={this.showModal} shape='round'>
              {t('buttons:upload')}
            </Button>
          </div> */}
        </div>
      </div>
    )
  }

  getMediaListing = list => {
    return list ? list.rows : []
  }

  allowUpdateStatus = () => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    return isSuperAdmin
  }

  allowOrgAdminUpdateStatus = () => {
    const {
      user: {
        info: { isAdmin }
      }
    } = this.props
    return isAdmin
  }

  allowUpdateOrgMedia = () => {
    const {
      user: {
        info: { isSuperAdmin, isAdmin }
      }
    } = this.props
    return isSuperAdmin || isAdmin
  }

  handlePagination = (type, event) => {
    const { pageSize, pageSizePublic, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return this.onChangePagePublic(event, pageSizePublic)
    } else if (type === general.MY_MEDIA) {
      this.onChangePage(event, pageSize)
    } else {
      return this.onChangePageOrg(event, pageSizeOrg)
    }
  }

  getCurrent = type => {
    const { page, pagePublic, pageOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pagePublic
    } else if (type === general.MY_MEDIA) {
      return page
    } else {
      return pageOrg
    }
  }

  getPageSize = type => {
    const { pageSizePublic, pageSize, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pageSizePublic
    } else if (type === general.MY_MEDIA) {
      return pageSize
    } else {
      return pageSizeOrg
    }
  }

  handleOrgLibraryUpdate = async item => {
    const { updateOrgLib, user } = this.props
    const action = general.ORG
    const query = {
      action,
      organization_id: user.info.active_organization_id,
      library: [item.id]
    }

    await updateOrgLib(query)
    await this.getLibrary()
    this.setState(
      {
        isFetching: false,
        orgMediaLoaded: false
      },
      () => {
        notification(action)
      }
    )
  }

  renderFile = (mediaList, type) => {
    const { tab } = this.state
    const { t, isPageBuilder } = this.props
    const media = this.getMediaListing(mediaList)
    const currentPage = this.getCurrent(type)
    const currentPageSize = this.getPageSize(type)
    return (
      <Fragment>
        {!mediaList ? (
          <div className="media-page__no-medial-list">
            {t('media:no_media')}
          </div>
        ) : (
          <Fragment>
            <div className="media-page__list">
              {media &&
                media.map((item, ind) => (
                  <div className="media-page__item" key={item.id}>
                    <Card
                      tab={tab}
                      handleViewDetails={this.handleViewDetails}
                      item={item}
                      allowUpdateStatus={this.allowUpdateStatus(type)}
                      allowOrgAdminUpdateStatus={this.allowOrgAdminUpdateStatus(
                        type
                      )}
                      allowUpdateOrgMedia={this.allowUpdateOrgMedia(type)}
                      ctrlLibraryStatus={(item, thumb) =>
                        this.handleUpdateLibraryStatus(item, thumb, ind)
                      }
                      ctrlHandleCheckCard={(val, thumb) =>
                        this.handleCheckCard(val, thumb, ind)
                      }
                      ctrlOrgLibrary={(item, thumb) =>
                        this.handleOrgLibraryUpdate(item, thumb, ind)
                      }
                      ctrlCheckPdfCard={this.handleMultipleFileDetails}
                      isPageBuilder={isPageBuilder}
                      allowMultiple={true}
                    />
                  </div>
                ))}
            </div>
            <div className="media-page__controls">
              <div className="media-page__pagination">
                <Pagination
                  total={mediaList.count}
                  current={currentPage}
                  pageSize={currentPageSize}
                  onChange={event => this.handlePagination(type, event)}
                />
              </div>
              <div className="media-page__buttons">
                <Button
                  onClick={() => {
                    this.addSelected()
                  }}
                  style={{ margin: '5px' }}
                >
                  {t('labels:done')}
                </Button>
                <Button
                  onClick={() => {
                    this.clearSelected()
                  }}
                  style={{ margin: '5px' }}
                >
                  {t('buttons:clear')}
                </Button>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderMedia = (mediaList, type) => {
    const { tab } = this.state
    const { t, isPageBuilder } = this.props
    const media = this.getMediaListing(mediaList)
    const currentPage = this.getCurrent(type)
    const currentPageSize = this.getPageSize(type)

    return (
      <Fragment>
        {!mediaList ? (
          <div className="media-page__no-medial-list">
            {t('media:no_media')}
          </div>
        ) : (
          <Fragment>
            <div className="media-page__list">
              {media &&
                media.map((item, ind) => (
                  <div className="media-page__item" key={item.id}>
                    <Card
                      tab={tab}
                      handleViewDetails={this.handleViewDetails}
                      item={item}
                      allowUpdateStatus={this.allowUpdateStatus(type)}
                      allowOrgAdminUpdateStatus={this.allowOrgAdminUpdateStatus(
                        type
                      )}
                      allowUpdateOrgMedia={this.allowUpdateOrgMedia(type)}
                      ctrlLibraryStatus={(item, thumb) =>
                        this.handleUpdateLibraryStatus(item, thumb, ind)
                      }
                      ctrlHandleCheckCard={(val, thumb) =>
                        this.handleCheckCard(val, thumb, ind)
                      }
                      ctrlOrgLibrary={(item, thumb) =>
                        this.handleOrgLibraryUpdate(item, thumb, ind)
                      }
                      isPageBuilder={isPageBuilder}
                    // isSuperAdmin={isSuperAdmin}
                    />
                  </div>
                ))}
            </div>
            <div className="media-page__controls">
              <Pagination
                total={mediaList.count}
                current={currentPage}
                pageSize={currentPageSize}
                onChange={event => this.handlePagination(type, event)}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  enableCloseModal = (progress, isFetching) => {
    const filterCancelProgressUpload = _.filter(
      progress,
      item => item.status === general.CANCELED
    )
    const checkCancelUpload =
      filterCancelProgressUpload.length === progress.length

    if (isFetching) {
      return !checkCancelUpload
    } else if (this.state.isRemovingMedia) {
      return true
    }

    return false
  }

  /**
   * Check upload progress bar status
   * if item.load not equal item.total display upload file size
   */
  checkedCompletUpload = item => {
    const { uploadSuccess } = this.props.library
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )

    if (uploadSuccess.length && filterSuccessFiles.length) {
      return false
    }
    return true
  }

  handleFilterClassName = isHideFilter => {
    return isHideFilter ? 'hide-filter' : ''
  }

  onFilter = (order, tab) => {
    this.setState({ isFetching: true })
    // Filter for My Media tab
    if (tab === general.MY_MEDIA) {
      this.setState(
        {
          page: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.getLibrary()
        }
      )
    }

    // Filter for Public Library tab
    if (tab === general.PUBLIC_MEDIA) {
      this.setState(
        {
          pagePublic: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    }

    // Filter for Org Library tab
    if (tab === general.ORGANIZATION_LIBRARY) {
      this.setState(
        {
          pageOrg: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  renderFilterMedia = tab => {
    const {
      t,
      user: {
        info: { isSuperAdmin, isAdmin }
      }
    } = this.props
    const { isHideFilter, mediaType } = this.state
    return (
      <div className="filter-container">
        <div
          className={`left-block ${this.handleFilterClassName(isHideFilter)}`}
        >
          <Fragment>
            <div className="filter">
              <p className="label">{t('media:media_type')}</p>
              <PopupMediaFilter
                lists={mediaType}
                ctrlHandleFilterChange={value =>
                  this.hangeFilterChange(value, tab)
                }
              />
            </div>
            <div className="status-button">
              {tab === general.MY_MEDIA && isSuperAdmin && (
                <Button
                  block
                  type="primary"
                  onClick={() => this.updateMediaStatus(general.PUBLIC)}
                >
                  {t('buttons:make_public')}
                </Button>
              )}
              {(isSuperAdmin || isAdmin) && (
                <Button
                  block
                  type="primary"
                  onClick={() => this.updateMediaStatus(general.PRIVATE)}
                >
                  {t('buttons:make_private')}
                </Button>
              )}
              {tab === general.MY_MEDIA && (
                <Button
                  block
                  type="danger"
                  onClick={() => this.setState({ showDeleteModal: true })}
                >
                  {t('buttons:delete')}
                </Button>
              )}
              {(isSuperAdmin || isAdmin) &&
                (tab === general.MY_MEDIA || tab === general.PUBLIC) && (
                  <Button
                    block
                    type="primary"
                    onClick={() => this.updateMediaStatus(general.ORG)}
                  >
                    {t('buttons:make_org')}
                  </Button>
                )}
            </div>
          </Fragment>
        </div>
        <div className="right-block">
          {/* &nbsp; */}
          <Filter
            data={filterData}
            defaultValue={filterData[0].text}
            callback={item => this.onFilter(item.value, tab)}
            label={t('labels:sort_by')}
          // bordered
          />
        </div>
      </div>
    )
  }

  getSelectedMedia = () => {
    const { data } = this.state
    let library = []
    if (data && !_.isEmpty(data.rows)) {
      library = _.map(
        _.filter(data.rows, item => {
          return item.is_checked
        }),
        'filename'
      )
    }
    return library
  }

  render() {
    const {
      modal,
      files,
      isFetching,
      data,
      publicLibrary,
      orgLibrary,
      fileNotCompatible,
      showStatusModal,
      mediaNoTopics,
      loadingModalButtion,
      isRemovingMedia,
      showDeleteModal,
      mazescenes,
      isFetchingMazeScenes,
      privateFiles,
      publicFiles,
      orgFiles
    } = this.state
    const {
      t,
      topics,
      library,
      isSendMail,
      isMazeCreate,
      imageDrop,
      droppedImage,
      handleUploadImage,
      handleClosePopup,
      file,
      isMazeBuilder = false,
      isGame = false
    } = this.props
    const { uploadProgress, uploadFetching } = library
    const { TabPane } = Tabs
    const selectedMedia = this.getSelectedMedia()

    return (
      <Fragment>
        <section className="section">
          <Row>
            <Col span={12}>
              <div className="head">
                <div className="head__title">{t('title')}</div>
                <div className="head__description">{t('subtitle')}</div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="section">
          <Tabs
            defaultActiveKey="1"
            onChange={this.handleChangeTabs}
            animated={false}
          >
            {!isMazeCreate && !isGame && (
              <TabPane
                tab={t('media:my_media')}
                key={general.MY_MEDIA}
                disabled={isSendMail || isMazeCreate}
              >
                {this.searchContainer(general.MY_MEDIA)}

                {this.renderFilterMedia(general.MY_MEDIA)}
                <Page>
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      {this.props.types === 'images'
                        ? this.renderMedia(data, general.MY_MEDIA)
                        : this.renderFile(privateFiles, general.MY_MEDIA)}
                    </>
                  )}
                </Page>
              </TabPane>
            )}
            {!isMazeCreate && !isGame && (
              <TabPane
                tab={t('media:public_library')}
                key={general.PUBLIC_MEDIA}
                disabled={isMazeCreate}
              >
                {this.searchContainer(general.PUBLIC_MEDIA)}

                {this.renderFilterMedia(general.PUBLIC_MEDIA)}
                <Page>
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      {this.props.types === 'images'
                        ? this.renderMedia(publicLibrary, general.PUBLIC_MEDIA)
                        : this.renderFile(publicFiles, general.PUBLIC_MEDIA)}
                    </>
                  )}
                </Page>
              </TabPane>
            )}
            {!isMazeCreate && !isGame && (
              <TabPane
                tab={t('media:organization_library')}
                key={general.ORGANIZATION_LIBRARY}
                disabled={isSendMail || isMazeCreate}
              >
                {this.searchContainer(general.ORGANIZATION_LIBRARY)}

                {this.renderFilterMedia(general.ORGANIZATION_LIBRARY)}
                <Page>
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      {this.props.types === 'images'
                        ? this.renderMedia(
                          orgLibrary,
                          general.ORGANIZATION_LIBRARY
                        )
                        : this.renderFile(
                          orgFiles,
                          general.ORGANIZATION_LIBRARY
                        )}
                    </>
                  )}
                </Page>
              </TabPane>
            )}
            {!isMazeCreate && isMazeBuilder && !isGame && (
              <TabPane
                tab={t('media:upload_from_computer')}
                key={general.UPLOAD_FROM_COMPUTER}
              >
                <div className="upload-modal insert-audio-detail">
                  <div className="upload-modal__inner">
                    {/* {uploadProgress.length === 0 && ( */}
                    <div className="upload-modal__dropzone">
                      <Dropzone
                        accept={
                          this.props.types === 'videos'
                            ? 'video/*'
                            : ['image/*']
                        }
                        multiple={false}
                        onDrop={imageDrop}
                      >
                        {({ isDragActive }) => (
                          <div
                            className={className('upload-dropzone', {
                              'upload-dropzone_active': isDragActive
                            })}
                          >
                            <div className="upload-dropzone__text-wrapper">
                              <img src={deactiveImageIcon} alt="" />
                              <p>{t('media:drop_files')}</p>
                              <p>{t('media:or')}</p>
                              <Button>{t('media:import_files')}</Button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {/* )} */}
                  </div>
                </div>
                {droppedImage && (
                  <EditImageContainer
                    image={droppedImage}
                    handleUploadImage={handleUploadImage}
                    handleClosePopup={handleClosePopup}
                    file={file}
                  />
                )}
              </TabPane>
            )}
            {isMazeCreate === 'scene' && (
              <TabPane tab={t('v4:maze_scenes')} key={general.MAZE_SCENES}>
                {this.searchContainer(general.MAZE_SCENES)}

                <Page>
                  {isFetchingMazeScenes ? (
                    <Spinner />
                  ) : (
                    <>{this.renderMedia(mazescenes, general.MAZE_SCENES)}</>
                  )}
                </Page>
              </TabPane>
            )}
            {isMazeCreate === 'avatar' && (
              <TabPane tab="MetaMaze Avatars" key={general.MAZE_SCENES}>
                {this.searchContainer(general.MAZE_SCENES)}

                <Page>
                  {isFetchingMazeScenes ? (
                    <Spinner />
                  ) : (
                    <>{this.renderMedia(mazescenes, general.MAZE_SCENES)}</>
                  )}
                </Page>
              </TabPane>
            )}

            {isGame && (
              <TabPane tab={'Games'} key={general.GAMES}>
                {this.searchContainer(general.GAMES)}

                <Page>
                  {isFetching ? (
                    <Spinner />
                  ) : (
                    <>{this.renderMedia(privateFiles, general.GAMES)}</>
                  )}
                </Page>
              </TabPane>
            )}
          </Tabs>
        </section>
        <Modal
          className="custom-ant-modal"
          destroyOnClose
          width={800}
          header={null}
          closable={false}
          footer={null}
          visible={modal}
          onCancel={this.hideModal}
          maskClosable={!this.enableCloseModal(uploadProgress, uploadFetching)}
        >
          <div className="upload-modal">
            <div className="upload-modal__inner">
              <h3 className="upload-modal__text">{t('media:import_file')}</h3>
              <div className="upload-modal__dropzone">
                <Dropzone
                  accept={[
                    'video/AVI',
                    'video/WMV',
                    'video/MP4',
                    'video/MOV',
                    'video/FLV',
                    'video/*',
                    'audio/*',
                    'image/*'
                  ]}
                  onDrop={this.onDrop}
                >
                  {({ isDragActive }) => (
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <div className="upload-dropzone__text-wrapper">
                        <img src={uploadMedia} alt="" />
                        <p className="upload-dropzone__text">
                          <span>{t('media:upload_file')}</span> {t('drag_drop')}
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>

              <div
                className={`upload-modal__list-wrapper ${this.uploadListClassName(
                  uploadProgress
                )}`}
              >
                <div className="upload-modal__list-container">
                  {fileNotCompatible && (
                    <div className="upload-modal__error-item-wrapper">
                      <div className="upload-modal__error-message-container">
                        <div className="upload-modal__error-bar" />
                        <div className="upload-modal__error-message">
                          {t('errors:upload_error')}
                        </div>
                      </div>

                      <Button
                        className="upload-modal__cancel-error"
                        type="primary"
                        onClick={this.cancelError}
                      >
                        {t('buttons:cancel')}
                      </Button>
                    </div>
                  )}

                  {uploadProgress.length > 0 && (
                    <div className="upload-modal__list">
                      {uploadProgress.map((item, ind) => (
                        <div key={item.uid} className="upload-modal__item">
                          <div className="upload-modal__thumbnail">
                            {getFileType(files[ind] && files[ind].type) ===
                              'image' ? (
                              <img
                                className="upload-modal__thumbnail__image"
                                src={URL.createObjectURL(
                                  files[ind] && files[ind]
                                )}
                                alt=""
                              />
                            ) : (
                              <div className="upload-modal__thumbnail__audio-video">
                                <Icon
                                  type={
                                    getFileType(
                                      files[ind] && files[ind].type
                                    ) === 'audio'
                                      ? 'audio'
                                      : 'play-circle'
                                  }
                                  style={{ color: '#0068FF', fontSize: '35px' }}
                                />
                              </div>
                            )}
                            <div className="upload-modal__thumbnail__details">
                              <span className="upload-modal__thumbnail__media-type">
                                {getFileType(files[ind] && files[ind].type)}
                              </span>
                              <span className="upload-modal__thumbnail__extention">
                                {getFileExtension(
                                  files[ind] && files[ind].type
                                )}
                              </span>
                              <span className="upload-modal__thumbnail__size">
                                {bytesToSize(files[ind] && files[ind].size)}
                              </span>
                            </div>
                          </div>
                          <div className="upload-modal-item">
                            <div className="upload-modal-item__percent-border">
                              <div
                                className={`upload-modal-item__percent ${item.status === general.CANCELED
                                  ? 'upload-modal-item__percent__cancel'
                                  : ''
                                  }`}
                                style={{ width: item.percent + '%' }}
                              />
                            </div>

                            {item.status !== general.CANCELED && (
                              <div className="upload-modal-item__weight">
                                {this.checkedCompletUpload(item) && (
                                  <span>
                                    {bytesToSize(item.loaded)} {t('of')}{' '}
                                    {bytesToSize(item.total)}
                                  </span>
                                )}
                                {!this.checkedCompletUpload(item) && (
                                  <span className="upload-modal-item__weight__complete">
                                    {files[ind] && files[ind].removed
                                      ? t('general:removed')
                                      : t('general:completed')}
                                  </span>
                                )}
                              </div>
                            )}

                            {item.status === general.CANCELED && (
                              <div className="upload-modal-item__weight">
                                <span className="upload-modal-item__weight__canceled">
                                  {t('general:canceled')}
                                </span>
                              </div>
                            )}

                            <AddMediaDetail
                              status={item.status}
                              mediaId={item.id}
                              fileType={getFileType(
                                files[ind] && files[ind].type
                              )}
                              topicList={topics.list}
                              isLoadingTopic={topics.isFetching}
                              isRemovingMedia={isRemovingMedia}
                              mediaRemoved={files[ind] && files[ind].removed}
                              ctrlHandleCancel={() =>
                                this.handleCancelUpload(item)
                              }
                              ctrlHandleRemove={() =>
                                this.handleRemoveMedia(item.id, ind)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="upload-modal__footer">
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={this.hideModal}
                disabled={this.enableCloseModal(uploadProgress, uploadFetching)}
              >
                {t('buttons:close')}
              </Button>
            </div>
          </div>
        </Modal>
        {showStatusModal && (
          <UpdateStatusModal
            mediaLists={mediaNoTopics}
            showModal={showStatusModal}
            loadingModalButtion={loadingModalButtion}
            topics={topics.list}
            ctrlHandleChangeTopic={this.handleCloseStatusModal}
            ctrlMakeMediaStatus={this.makeMediaStatus}
          />
        )}
        {showDeleteModal && (
          <Modal
            visible={showDeleteModal}
            centered
            className="delete-modal"
            title={t('media:delete_warning')}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <Button
                key="back"
                shape="round"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                No
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="danger"
                onClick={this.deleteMedia}
              >
                Yes
              </Button>
            ]}
          >
            <p style={{ color: '#333333' }}>{t('media:delete_message')}</p>
            <div
              style={{
                backgroundColor: '#f1f1f1',
                borderRadius: '.5rem',
                padding: '1rem'
              }}
            >
              {!_.isEmpty(selectedMedia) &&
                selectedMedia.map((item, index) => (
                  <div className="selectedMedia">
                    <div className="titleMedia">
                      {index + 1}. {item}
                    </div>
                  </div>
                ))}
            </div>
          </Modal>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('media')(PopupMedia)
