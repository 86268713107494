import React, { Component, Fragment } from 'react'

import CourseCreateTopPanel from '../Create/internal/CourseCreateTopPanel'
import CourseCreateRoutes from '../Create/routes'
import history from '../../../history'
import { Alert } from 'antd'

const courseFieldMatrix = course => {
  const {
    title,
    topics,
    cover,
    marketplace = {},
    description,
    organiser,
    cert_id,
    badge_id,
    start_date: startDate,
    exp_date: expDate,
    pass_score,
    host_emails,
    marketplace_option,
    attachments,
    attachment_details,
    categories,
    template
  } = course
  return {
    courseTitle: title,
    courseOrganiser: organiser,
    courseTags: topics || [],
    courseCertId: cert_id,
    courseImg: cover,
    templateData: template,
    courseBadgeId: badge_id,
    courseDescription: description || '',
    coursePassScore: pass_score,
    courseCategory: marketplace.category_id,
    courseIsFree: marketplace.is_free,
    courseShareProfile: marketplace.is_shared_profile,
    coursePrice: marketplace.price,
    coursePriceToEdit: marketplace.price1,
    courseVideoLink: marketplace.video_link,
    courseNoOfVideos: marketplace.no_of_video,
    courseNoOfLessons: marketplace.no_of_lesson,
    courseOrganization: marketplace.organization_id,
    courseGroup: marketplace.group_id,
    courseObjectives: marketplace.course_obj,
    courseOutline: marketplace.course_outline,
    courseMarketPlaceDescription: marketplace.course_description,
    courseMarketPlaceOption: marketplace_option,
    courseTrainerLinkedIn: marketplace.linkedin,
    courseEditPermission: marketplace.edit_permission,
    courseTrainerBio: marketplace.bio,
    courseStartDate: startDate,
    courseExpDate: expDate,
    courseEmails: host_emails,
    courseAttachments: attachments,
    courseAttachmentDetails: attachment_details,
    courseCategories: categories.length > 0 ? categories.map(el => el.id) : []
  }
}

class CourseEdit extends Component {
  state = {
    isCourseReducerUpdated: false,
    isCourseError: null
  }
  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getCourseById,
      courseBuilderActions: { handleCourseInput }
    } = this.props
    handleCourseInput('courseId', id)

    getCourseById(id)
      .then(async response => {
        if (response) {
          if (!response.is_access) {
            history.push(`/courses/${id}`)
            return
          }
          const data = courseFieldMatrix(response)

          await Object.keys(data).forEach(key =>
            handleCourseInput(key, data[key])
          )
        }
      })
      .then(() => {
        this.setState({
          isCourseReducerUpdated: true
        })
      })
      .catch(error => {
        this.setState({
          isCourseError: error.message
        })
      })
  }

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props

    const { isCourseReducerUpdated, isCourseError } = this.state

    const mode = 'edit'

    if (isCourseError)
      return (
        <section className="section">
          <Alert
            showIcon
            type="error"
            message="Course Not Found"
            description={isCourseError}
          />
        </section>
      )

    return (
      <Fragment>
        <CourseCreateTopPanel mode={mode} id={id} />
        <CourseCreateRoutes
          mode={mode}
          isCourseReducerUpdated={isCourseReducerUpdated}
        />
      </Fragment>
    )
  }
}

export default CourseEdit
