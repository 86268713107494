import React, { Component } from 'react'
import './styles.scss'
import DeleteIcon from '../../assets/bin.svg'
import { Icon } from 'antd'

const TEXT = {
  line1: 'XYZ Organization',
  line2: 'CERTIFIES THAT',
  line3: 'User Name comes here',
  line4: 'has completed the course.',
  line5: 'Name of Person',
  line6: 'Post',
  line7: 'Name of Person',
  line8: 'Post'
}

const TYPES = {
  ICON: 'Icon',
  SIGNATURE: 'Signature',
  SIGNATURE_TWO: 'SignatureTwo',
  LOGO: 'Logo',
  CERTIFICATE: 'Cert'
}
export default class Template extends Component {
  constructor(props) {
    super(props)
    this.state = {
      line1: TEXT.line1,
      line2: TEXT.line2,
      line3: TEXT.line3,
      line4: TEXT.line4,
      line5: TEXT.line5,
      line6: TEXT.line6,
      line7: TEXT.line7,
      line8: TEXT.line8,
      template: null
    }
  }

  handleText = e => {
    const { onText } = this.props
    const name = e.target.name
    const value = e.target.value
    this.setState(
      {
        [name]: value
      },
      () => {
        onText({
          name: name,
          value: value
        })
      }
    )
  }

  componentDidMount() {
    const { userView } = this.props
    if (userView) {
      this.putTextInCanvas()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.putTextInCanvas()
    }
  }

  putTextInCanvas = () => {
    const {
      certificateHistory,
      userName,
      userView,
      clearCertificate,
      onClear
    } = this.props
    console.log(certificateHistory)
    if (clearCertificate) {
      this.setState(
        {
          line1: TEXT.line1,
          line2: TEXT.line2,
          line4: TEXT.line4,
          line5: TEXT.line5,
          line6: TEXT.line6,
          line7: TEXT.line7,
          line8: TEXT.line8
        },
        () => onClear()
      )
    } else if (certificateHistory) {
      this.setState({
        line1: certificateHistory.line1 || TEXT.line1,
        line2: certificateHistory.line2 || TEXT.line2,
        line4: certificateHistory.line4 || TEXT.line4,
        line5: certificateHistory.line5 || TEXT.line5,
        line6: certificateHistory.line6 || TEXT.line6,
        line7: certificateHistory.line7 || TEXT.line7,
        line8: certificateHistory.line8 || TEXT.line8
      })
    }
    if (userView) {
      this.setState({
        line3: userName,
        template: certificateHistory.selectedTemplate
      })
    }
  }

  clearImage = type => {
    const { onClearImage } = this.props
    return (
      <div
        className="certificate_clear_image"
        onClick={() => onClearImage(type)}
      >
        <img alt="" src={DeleteIcon} />
      </div>
    )
  }

  render() {
    const {
      icon,
      userIcon,
      signaturesById,
      userView,
      template,
      addSignature,
      iconById,
      logoById,
      isFetching
    } = this.props
    const certificateIcon = icon || userIcon

    if (isFetching)
      return (
        <div className="certificate-loading">
          <p>
            Generating Certificate... <Icon type="loading" />
          </p>
        </div>
      )

    return (
      <div
        className="certificate-template"
        style={{
          backgroundImage: `url(${userView ? this.state.template : template})`,
          pointerEvents: userView ? 'none' : 'all'
        }}
      >
        <div className="certificate-logo">
          {!logoById && <div style={{ height: '10px', width: '30px' }} />}
          {logoById && !userView && (
            <img
              alt=""
              src={logoById}
              style={{ height: '30px', width: 'auto' }}
            />
          )}
          {logoById && userView && (
            <img
              alt=""
              src={logoById}
              crossOrigin="anonymous"
              style={{ height: '30px', width: '30px' }}
            />
          )}
          {logoById && this.clearImage(TYPES.LOGO)}
        </div>
        <div className="certificate-title">
          <input
            type="text"
            name="line1"
            onChange={this.handleText}
            value={this.state.line1}
          />
        </div>
        <div className="certificate-midtext">
          <input
            type="text"
            name="line2"
            onChange={this.handleText}
            value={this.state.line2}
          />
        </div>
        <div className="certificate-username">
          <input
            type="text"
            name="line3"
            onChange={this.handleText}
            value={this.state.line3}
            disabled={!userView || true}
          />
        </div>
        <div className="certificate-text">
          <textarea
            maxLength="150"
            spellCheck="false"
            onChange={this.handleText}
            value={this.state.line4}
            name="line4"
          />

          <span className="certificate-text-length">
            {this.state.line4.length} / 150
          </span>
        </div>
        <div className="certificate-bottom">
          {addSignature && (
            <div className="signature">
              <div className="signature-img">
                {!signaturesById[1] && (
                  <div style={{ height: '20px', width: '20px' }} />
                )}
                {signaturesById && signaturesById[1] && !userView && (
                  <img
                    alt=""
                    src={signaturesById[1].link}
                    style={{
                      height: '30px',
                      width: '60px',
                      objectFit: 'contain'
                    }}
                  />
                )}
                {signaturesById && signaturesById[1] && userView && (
                  <img
                    alt=""
                    crossOrigin="anonymous"
                    src={signaturesById[1].link}
                    style={{
                      height: '30px',
                      width: '60px',
                      objectFit: 'contain'
                    }}
                  />
                )}
                {signaturesById &&
                  signaturesById[1] &&
                  this.clearImage(TYPES.SIGNATURE_TWO)}
              </div>
              <span>_________________</span>
              <div className="signature_name">
                <input
                  type="text"
                  name="line5"
                  onChange={this.handleText}
                  value={this.state.line5}
                />
              </div>
              <div className="signature_sub">
                <input
                  type="text"
                  name="line6"
                  onChange={this.handleText}
                  value={this.state.line6}
                />
              </div>
            </div>
          )}
          {!addSignature && (
            <div style={{ height: '76px', width: '158px' }}></div>
          )}
          {certificateIcon && (
            <div className="icon">
              {userView && (
                <img
                  alt=""
                  crossOrigin="anonymous"
                  src={
                    certificateIcon === userIcon ? iconById : certificateIcon
                  }
                  style={{
                    height: '50px',
                    width: '50px',
                    objectFit: 'contain'
                  }}
                />
              )}
              {!userView && (
                <img
                  alt=""
                  src={
                    certificateIcon === userIcon ? iconById : certificateIcon
                  }
                  style={{
                    height: '50px',
                    width: '50px',
                    objectFit: 'contain'
                  }}
                />
              )}
              {this.clearImage(TYPES.ICON)}
            </div>
          )}
          <div className="signature">
            <div className="signature-img">
              {!signaturesById[0] && (
                <div style={{ height: '20px', width: '20px' }} />
              )}
              {signaturesById && signaturesById[0] && !userView && (
                <img
                  alt=""
                  src={signaturesById[0].link}
                  style={{
                    height: '30px',
                    width: '60px',
                    objectFit: 'contain'
                  }}
                />
              )}
              {signaturesById && signaturesById[0] && userView && (
                <img
                  alt=""
                  crossOrigin="anonymous"
                  src={signaturesById[0].link}
                  style={{
                    height: '30px',
                    width: '60px',
                    objectFit: 'contain'
                  }}
                />
              )}
              {signaturesById &&
                signaturesById[0] &&
                this.clearImage(TYPES.SIGNATURE)}
            </div>
            <span>_________________</span>
            <div className="signature_name">
              <input
                type="text"
                name="line7"
                onChange={this.handleText}
                value={this.state.line7}
              />
            </div>
            <div className="signature_sub">
              <input
                type="text"
                name="line8"
                onChange={this.handleText}
                value={this.state.line8}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
