import { Progress } from 'antd'
import { Auth } from 'aws-amplify'
import React, { useEffect, useMemo, useState } from 'react'
import { Unity, useUnityContext } from 'react-unity-webgl-latest'
import { config } from '../../../../../../../constants'
import './SimulationStyles.scss'

const SimulationUnityView = ({
  unityUrl,
  simulationId,
  userId,
  token,
  ids,
  isEnableModal
}) => {
  const { loaderUrl, dataUrl, frameworkUrl, codeUrl } = unityUrl
  const { lessonId, courseId, pageId } = ids
  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    isLoaded,
    unload,
    loadingProgression,
    error: unityError
  } = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl
  })

  const [unityUnloaded, setUnityUnloaded] = useState(false)

  useEffect(() => {
    return () => {
      handleUnloadUnity()
    }
  }, [])

  const loadingPercentage = useMemo(
    () => Math.round(loadingProgression * 100),
    [loadingProgression]
  )

  const getAccessJwtToken = async () => {
    const session = await Auth.currentSession()
    const newToken = await session.idToken.jwtToken
    return newToken
  }

  const handleNewToken = async msg => {
    if (msg === 'token_needed') {
      const token = await getAccessJwtToken()
      sendMessage('Data', 'Get_Fresh_Token', String(token))
    }
  }

  const handleUnloadUnity = () => {
    if (isLoaded && unityProvider) {
      unload()
        .then(() => {
          console.log('Unity instance successfully unloaded.')
          setUnityUnloaded(true)
        })
        .catch(error => {
          console.error('Error during Unity unload:', error)
        })
    } else {
      console.warn('Unity instance is not instantiated; skipping unload.')
    }
  }

  useEffect(() => {
    if (isLoaded) {
      sendMessage('Data', 'Get_courseid', String(courseId))
      sendMessage('Data', 'Get_userid', String(userId))
      sendMessage('Data', 'Get_simulationid', String(simulationId))
      sendMessage('Data', 'Get_token', String(token))
      sendMessage('Data', 'Get_baseurl', String(config.baseURL))
      sendMessage('Data', 'Get_lessonid', String(lessonId))
      sendMessage('Data', 'Get_pageid', String(pageId))
      addEventListener('GetToken', handleNewToken)
      addEventListener('ExitGame', handleUnloadUnity)
    }
    return () => {
      removeEventListener('GetToken', handleNewToken)
      removeEventListener('ExitGame', handleUnloadUnity)
    }
  }, [isLoaded])

  useEffect(() => {
    handleUnloadUnity()
  }, [isEnableModal])

  return (
    <div className="simulation-wrapper">
      <div className="simulation-unity-container" id="simulationUnity">
        <div className="simulation-aspect-wrapper">
          {unityUnloaded && (
            <div className="simulation-unloaded">
              <span>Unity instance unloaded</span>
            </div>
          )}

          {isLoaded === false && !unityError && (
            <div className="simulation-loading">
              <div className="simulation-progress">
                <Progress type="circle" percent={loadingPercentage} />
              </div>
              <span>Loading Simulation...</span>
            </div>
          )}

          {!unityUnloaded && (
            <div className="simulation-player">
              <Unity unityProvider={unityProvider} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SimulationUnityView
