import React, { useEffect, useState } from 'react'
import { Card, Row, Col, message, Modal, Form, Spin, Radio } from 'antd'
import { ImageSelect } from '../../Form'
import './AiTemplateSelection.scss'
import { api } from '../../../services'
import { BlockPicker } from 'react-color'
const colors = [
  '#F8F9FA',
  '#E9ECEF',
  '#DEE2E6',
  '#CED4DA', // Light Neutrals
  '#FFF8E1',
  '#FFF3E0',
  '#E8F5E9',
  '#E3F2FD', // Light Pastels
  '#FFEBEE',
  '#FFCDD2',
  '#EDE7F6', // More Light Pastels
  '#FCE4EC',
  '#F3E5F5',
  '#EDE7F6',
  '#E8F5E9', // Subtle Colors
  '#FBE9E7',
  '#FFFDE7',
  '#F1F8E9', // Soft Muted Tones
  '#D9E3F0',
  '#F47373',
  '#697689',
  '#37D67A',
  '#2CCCE4',
  '#555555',
  '#DCE775',
  '#FF8A65',
  '#BA68C8',
  '#FFB74D'
]
const AiTemplateSelection = ({
  isGeneratingCourse,
  courseTemplateData,
  type,
  onConfirm,
  open,
  close,
  form,
  courrseContent
}) => {
  useEffect(() => {
    getTemplateList()
  }, [open])
  const [templateData, setTemplateData] = useState({
    template_id: '',
    background_color: null,
    background_id: null,
    logo_id: null,
    background_img: null,
    logo_img: null,
    tocs: [courrseContent]
  })
  const [templateImage, setTemplateImage] = useState({
    background: null,
    logo: null
  })
  const [loading, setLoading] = useState(false)
  const [backgroundType, setBackgroundType] = useState('IMAGE')
  const [teplateList, setTemplateList] = useState([])
  useEffect(() => {
    if (type && courseTemplateData.template_id) {
      setTemplateData({
        ...templateData,
        background_img: courseTemplateData.background_img,
        logo_img: courseTemplateData.logo_img,
        template_id: courseTemplateData.template_id,
        background_color: courseTemplateData.background_color,
        background_id: courseTemplateData.background_id,
        logo_id: courseTemplateData.logo_id
      })

      setBackgroundType(courseTemplateData.background_color ? 'COLOR' : 'IMAGE')
    }
  }, [courseTemplateData])
  const onCoverChange = (cover, coverType) => {
    if (coverType === 'bg') {
      setTemplateData({
        ...templateData,
        background_id: cover.id,
        background_img: cover
      })
    } else {
      setTemplateData({ ...templateData, logo_id: cover.id, logo_img: cover })
    }
  }
  const getTemplateList = async () => {
    setLoading(true)
    let res = await api.courses.getTemplateCourseList()
    if (res.data && !res.statusText) {
      setTemplateList(res.data)
      if (!courseTemplateData) {
        setTemplateData({
          ...templateData,
          template_id: res.data[0].template_id
        })
      }
    } else {
      message.error(res.statusText || 'Failed to get template list!')
    }
    setLoading(false)
  }
  const onChange = e => {
    setTemplateData({
      ...templateData,
      background_color: null,
      background_id: null
    })
    setBackgroundType(e.target.value)
  }
  const handleChangeComplete = color => {
    setTemplateData({ ...templateData, background_color: color.hex })
  }
  const selectTemplate = () => {
    if (!templateData.template_id) {
      message.error('Please select a template layout')
      return
    }
    onConfirm(templateData)
  }

  return (
    <Modal
      title="Template Selection"
      visible={open}
      confirmLoading={isGeneratingCourse}
      onOk={selectTemplate}
      okText={
        type
          ? courseTemplateData && courseTemplateData.template_id
            ? 'Update Course Template'
            : 'Add Course Template'
          : 'Create Course Now'
      }
      onCancel={close}
      centered
      className="add-new-template"
      maskClosable={false}
    >
      <div className="template-body">
        <Row gutter={[16, 16]} className="mb-8">
          <Col xs={24} md={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Radio.Group onChange={onChange} value={backgroundType}>
                  <Radio.Button value={'IMAGE'}>Background Image</Radio.Button>
                  <Radio.Button value={'COLOR'}>Background Color</Radio.Button>
                </Radio.Group>
              </Col>
              <Col span={24}>
                {backgroundType === 'IMAGE' ? (
                  <ImageSelect
                    form={form}
                    label={'Template Background'}
                    coverInput={templateData.background_img}
                    onCoverChange={cover => {
                      onCoverChange(cover, 'bg')
                    }}
                  />
                ) : (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label={<div>Template Background Color</div>}
                    colon={false}
                    required={false}
                  >
                    <BlockPicker
                      color={templateData.background_color || ''}
                      onChangeComplete={handleChangeComplete}
                      colors={colors}
                      styles={{
                        default: {
                          card: {
                            width: '100%'
                          },
                          body: {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '4px',
                            borderLeft: '1.5px solid #e3e3e3',
                            borderRight: '1.5px solid #e3e3e3',
                            borderBottom: '1.5px solid #e3e3e3',
                            borderTop: 'none',
                            borderRadius: '0 0 8px 8px'
                          }
                        }
                      }}
                    />{' '}
                  </Form.Item>
                )}
              </Col>
              <Col span={24}>
                <ImageSelect
                  form={form}
                  label={'Template Logo'}
                  coverInput={templateData.logo_img}
                  onCoverChange={cover => {
                    onCoverChange(cover, 'logo')
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <div className="template-body-title">Select a Template Layout</div>
            <Spin spinning={loading}>
              <div className="template-body-layout">
                {!loading && teplateList.length > 0 ? (
                  teplateList.map((template, index) => (
                    <Card
                      className="template-body-layout-card"
                      key={index}
                      hoverable
                      style={{
                        border:
                          templateData.template_id === template.template_id
                            ? '2px solid #1890ff'
                            : '1px solid #f0f0f0',
                        backgroundColor:
                          templateData.template_id === template.template_id
                            ? '#e6f7ff'
                            : '#fff',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        setTemplateData({
                          ...templateData,
                          template_id: template.template_id
                        })
                      }
                    >
                      <div className="template-body-layout-card-body">
                        <img
                          src={template.template}
                          alt={`Sample ${template.content_id}`}
                        />
                      </div>
                    </Card>
                  ))
                ) : (
                  <Card
                    className="template-body-layout-card"
                    hoverable
                    style={{
                      border: '1px solid #f0f0f0',
                      backgroundColor: '#fff',
                      cursor: 'pointer'
                    }}
                  >
                    <div className="template-body-layout-card-body">
                      {!loading && 'Template data unavailable.'}
                    </div>
                  </Card>
                )}
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
export default Form.create({ name: 'template_form' })(AiTemplateSelection)
