import React from 'react'
import { Checkbox } from 'antd'
import { isEmpty } from 'lodash'
import { Field, Row, Col } from '../../styles'
import { Organization } from '../../styles/blocks/orgGroupSelector'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

function OrganizationPicker(props) {
  const handleChange = id => {
    const { onChange } = props

    if (onChange) {
      onChange(id)
    }
  }

  const { selectedOrganizations = [], activeOrgId, selectedType } = props

  const user = useSelector(state => state.user)
  const { isSuperAdmin } = user.info

  const orgList = useSelector(state => state.organizations.list.rows)

  const { t } = useTranslation()

  const list =
    orgList.length > 0 &&
    orgList.filter(i => isSuperAdmin || (i.role && i.role.name === 'Admin'))

  const checkSelectedType = typeId =>
    Array.isArray(selectedType)
      ? selectedType.includes(typeId)
      : selectedType === typeId

  return (
    <Field>
      <Field.Label>{t('labels:select_organization')}</Field.Label>
      <Field.Control>
        <Row style={{ marginBottom: '-3.2rem' }}>
          {!isEmpty(list) &&
            list.map(organization => {
              const { id, title, logo } = organization

              return (
                <Col key={id} md={3}>
                  <Organization
                    active={id === activeOrgId}
                    onClick={() => handleChange(id)}
                    disabled={(checkSelectedType(1) || checkSelectedType(2)) ? false : id !== activeOrgId}
                  >
                    <Organization.Checkbox>
                      <Checkbox checked={selectedOrganizations.includes(id)} />
                    </Organization.Checkbox>
                    {logo ? (
                      <Organization.Image src={logo ? logo.link : ''} alt="" />
                    ) : (
                      <Organization.DefaultImage>
                        {title.charAt(0).toUpperCase()}
                      </Organization.DefaultImage>
                    )}

                    <Organization.Name>{title}</Organization.Name>
                  </Organization>
                </Col>
              )
            })}
        </Row>
      </Field.Control>
    </Field>
  )
}

export default OrganizationPicker
