import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {
  UploadOutlined,
  SoundOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import './styles.scss'

import {
  Spin,
  Select,
  Button,
  Input,
  Row,
  Col,
  Typography,
  Upload,
  Divider
} from 'antd'
import ElevenLabsControls from './ElevenLabsControls'
import { getSession } from '../../../../../../services/api/user'
import { config } from '../../../../../../constants'

import GalleryIcon from '../../../../../../assets/gallery_icon.png'
import AvatarIcon from '../../../../../../assets/avatarIcon2.png'
import SoundIcon from '../../../../../../assets/sound_icon.png'
import MaleIcon from '../../../../../../assets/male_icon.png'
import FemaleIcon from '../../../../../../assets/female_icon.png'

const { Option } = Select
const { Text } = Typography

const MazeRightPanel = ({
  audio,
  onAddVoice,
  background,
  onAddBackground,
  isBackgroundLoading,
  mazeAvatar,
  backgroundError,
  voiceError,
  onAvatarChange,
  handleElevenLabsVoiceSelect,
  voice_type,
  voice_accent,
  voice_name,
  mazeAvatarId,
  isELEnabled,
  isELClonedEnabled,
  handleVoiceType,
  upload
}) => {
  const [uploadedVoices, setUploadedVoices] = useState([])
  const [checkStatus, setCheckStatus] = useState(0)
  const [loadingList, setLoadingList] = useState(false)
  const [selectedAudio, setSelectedAudio] = useState(null)
  const [voiceInputName, setVoiceInputName] = useState('')
  const [token, setToken] = useState('')
  const [gender, setGender] = useState('')
  const [error, setError] = useState(false)

  const user = useSelector(state => state.user)
  console.log('CHECK USER Info', user.info.active_organization_id)
  const handleVoiceTypeChange = value => {
    handleVoiceType(value)
  }

  useEffect(() => {
    !voice_type && handleVoiceType(voice_type)
  }, [])

  // useEffect(() => {
  //   if (voice_type === 'elevenlabs_clone_voice' && selectedAudio) {
  //     const data = {
  //       selectedId: selectedAudio,
  //       selectedVoiceType: "elevenlabs",
  //       selectedAccent: "",
  //       selectedVoice: ""
  //     }
  //     handleElevenLabsVoiceSelect(data)
  //   }
  // }, [selectedAudio]);

  const fetchClonedVoices = tkn => {
    const apiUrl = `${config.baseURL}podcastai/voices?org=${user.info.active_organization_id}`
    console.log('CHECK TOKEN', token)
    setLoadingList(true)
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${tkn}`
        }
      })
      .then(response => {
        console.log('Data from GET voices:', response)
        setLoadingList(false)
        const filteredData = response.data.voices.filter(
          item => item.category === 'cloned'
        )
        console.log('Filtered Data from GET voices:', filteredData)
        filteredData.forEach(item => {
          setUploadedVoices(prev => [
            ...prev,
            {
              name: item.name,
              voice_id: item.voice_id,
              gender:
                item.labels && item.labels.gender ? item.labels.gender : 'male'
            }
          ])
        })
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }

  useEffect(() => {
    getSession().then(session => {
      console.log('SESSION CHECK: ', session.idToken.jwtToken)
      setToken(session.idToken.jwtToken)

      if (voice_type === 'elevenlabs_clone_voice') {
        setUploadedVoices([])
        fetchClonedVoices(session.idToken.jwtToken)
      }
    })
  }, [voice_type])

  useEffect(() => {
    if (uploadedVoices.length > 0) {
      setSelectedAudio({
        name: uploadedVoices[0].name,
        voice_id: uploadedVoices[0].voice_id
      })
    }
  }, [uploadedVoices])

  const uploadFile = file => {
    if (!file) {
      return
    }
    if (!gender) {
      setError(true)
      return
    }
    setCheckStatus(1)
    upload(file)
      .then(res => {
        console.log('Uploaded successfully', res.link)
        setCheckStatus(2)
        const apiUrl = `${config.baseURL}podcastai/voices`
        const headers = {
          Authorization: `Bearer ${token}`
        }
        const body = {
          audio_url: res.link,
          name: voiceInputName
            ? voiceInputName
            : `SHL_Cloned_Voice_${Math.floor(Math.random() * 1000) + 1}`,
          labels: {
            gender: gender
          }
        }

        return axios.post(apiUrl, body, { headers })
      })
      .then(response => {
        console.log('API response Clone', response.data)
        setUploadedVoices(prev => [
          ...prev,
          {
            name: response.data.name,
            voice_id: response.data.voice_id,
            gender:
              response.data.labels && response.data.labels.gender
                ? response.data.labels.gender
                : 'male'
          }
        ])
        setSelectedAudio(response.data.name)
        setCheckStatus(3)
        setTimeout(() => {
          setCheckStatus(0)
        }, 2000)
        const data = {
          selectedId: response.data.voice_id,
          selectedVoiceType: 'elevenlabs_clone_voice',
          selectedAccent: '',
          selectedVoice: response.data.name,
          selectedGender: response.data.labels.gender
        }
        handleElevenLabsVoiceSelect(data)
      })
      .catch(error => {
        console.error('Error occurred', error)
        setTimeout(() => {
          setCheckStatus(0)
        }, 2000)
      })
  }

  const handleFileUpload = info => {
    console.log('Log file', info)
    const file = info.file
    uploadFile(file)
    // if (uploadedVoices.length < 5) {
    //   uploadFile(file);

    // } else {
    //   console.log('Maximum 5 files can be uploaded.');
    // }
  }

  const handleDelete = voice_id => {
    const apiUrl = `${config.baseURL}podcastai/voices`
    axios
      .delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          voice_id: voice_id
        }
      })
      .then(response => {
        console.log('Deleted successfully:', response)
        // fetchClonedVoices();
        // Update setUploadedVoices to remove the deleted voice_id
        setUploadedVoices(prev =>
          prev.filter(item => item.voice_id !== response.data.voice_id)
        )
      })
      .catch(error => {
        console.error('Error deleting voice:', error)
      })
  }

  const handleGenderChange = value => {
    setGender(value)
    console.log('Selected Gender:', value)
    setError(false)
  }

  const handleBlur = () => {
    if (!gender) {
      setError(true)
    }
  }

  const onChangeVoiceList = value => {
    setSelectedAudio(value)
    if (voice_type === 'elevenlabs_clone_voice' && value) {
      const data = {
        selectedId: value.voice_id,
        selectedVoiceType: 'elevenlabs_clone_voice',
        selectedAccent: '',
        selectedVoice: value.name,
        selectedGender: value.gender
      }
      handleElevenLabsVoiceSelect(data)
    }
  }

  const renderControls = () => {
    if (voice_type === 'elevenlabs') {
      return (
        <ElevenLabsControls
          selectedVoiceType={voice_type}
          handleElevenLabsVoiceSelect={handleElevenLabsVoiceSelect}
          mazeVoiceAccent={voice_accent}
          voice_name={voice_name}
          mazeAvatarId={mazeAvatarId}
        />
      )
    } else if (voice_type === 'elevenlabs_clone_voice') {
      const progressText =
        checkStatus === 1
          ? 'Wait, processing...'
          : checkStatus === 2
          ? 'Creating voice...'
          : checkStatus === 3
          ? 'Voice creation successful'
          : ''
      return (
        <div>
          <Divider>Create Voice</Divider>
          {/* Enter Voice Name */}
          <Row gutter={[16, 16]} align="middle">
            <Col span={8}>
              <Text>Enter Voice Name</Text>
            </Col>
            <Col span={16}>
              <Input
                placeholder="Enter voice name"
                value={voiceInputName}
                onChange={e => setVoiceInputName(e.target.value)}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} align="middle">
            <Col span={8}>
              <Text>Select Gender</Text>
            </Col>
            <Col span={16}>
              <Select
                value={gender}
                onChange={handleGenderChange}
                onBlur={handleBlur}
                style={{
                  width: 200,
                  borderColor: error ? 'red' : 'transparent'
                }}
                placeholder="Select Gender"
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
              {error && (
                <div style={{ color: 'red', marginTop: 5 }}>
                  Please select voice gender
                </div>
              )}
            </Col>
          </Row>

          {/* Upload Audio */}
          <Row gutter={[16, 16]} align="middle">
            <Col span={8}>
              <Text>Upload Your Voice</Text>
            </Col>
            <Col span={16}>
              <Upload
                accept=".mp3"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={handleFileUpload}
              >
                <Button
                  disabled={
                    checkStatus === 1 || checkStatus === 2 || checkStatus === 3
                  }
                >
                  <UploadOutlined /> Upload your voice (.mp3)
                </Button>
              </Upload>
              <div style={{ marginTop: 8 }}>
                <Text style={{ color: 'green', fontSize: 12 }}>
                  {progressText}
                </Text>
              </div>
            </Col>
          </Row>
          <Divider></Divider>
          <Row gutter={[16, 16]} align="middle">
            <Col span={8}>
              <Text>Cloned Voices</Text>
            </Col>
            <Col span={16}>
              <Select
                placeholder="Select Cloned Voice"
                value={
                  selectedAudio && selectedAudio.name
                    ? selectedAudio.name
                    : undefined
                }
                disabled={loadingList}
                onChange={name => {
                  const selectedVoice = uploadedVoices.find(
                    voice => voice.name === name
                  )
                  onChangeVoiceList({
                    voice_id: selectedVoice.voice_id,
                    name: selectedVoice.name,
                    gender: selectedVoice.gender
                  })
                }}
              >
                {uploadedVoices.map(voice => (
                  <Option key={voice.voice_id} value={voice.name}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {voice.gender === 'male' ? (
                          <img
                            src={MaleIcon}
                            alt="Male"
                            style={{
                              marginRight: '8px',
                              width: '16px',
                              height: '16px'
                            }}
                          />
                        ) : (
                          <img
                            src={FemaleIcon}
                            alt="Female"
                            style={{
                              marginRight: '8px',
                              width: '16px',
                              height: '16px'
                            }}
                          />
                        )}
                        {voice.name}
                      </span>

                      {selectedAudio && selectedAudio.name !== voice.name && (
                        <DeleteOutlined
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            handleDelete(voice.voice_id)
                          }}
                          style={{ color: 'red', marginLeft: '10px' }}
                        />
                      )}
                    </div>
                  </Option>
                ))}
              </Select>
              <div
                style={{ marginTop: '8px', fontSize: '12px', color: '#888' }}
              >
                {!loadingList
                  ? `Uploaded Voices ${uploadedVoices.length} / 5 (Max)`
                  : 'Loading list...'}
              </div>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={24}>
              <div
                className="maze_action column"
                style={{
                  backgroundImage: mazeAvatar
                    ? `url('${mazeAvatar.link}')`
                    : 'none',
                  borderColor: backgroundError ? 'red' : '#d7d4d4'
                }}
                onClick={() => onAvatarChange()}
              >
                {loadingList.length === 0 ? (
                  <Spin spinning={true} />
                ) : (
                  <>
                    <img
                      alt=""
                      src={AvatarIcon}
                      style={{
                        objectFit: 'cover',
                        marginTop: '4px',
                        marginBottom: '-8px'
                      }}
                      height="80px"
                      width="80px"
                    />
                    <span
                      style={{
                        color: background ? 'white' : '#136cfb',
                        backgroundColor: background ? '#136cfb' : 'none'
                      }}
                    >
                      {mazeAvatar ? 'Avatar added' : 'Select Avatar'}
                    </span>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <>
          <div
            className="maze_action column"
            style={{
              backgroundImage: mazeAvatar
                ? `url('${mazeAvatar.link}')`
                : 'none',
              borderColor: backgroundError ? 'red' : '#d7d4d4'
            }}
            onClick={() => onAvatarChange()}
          >
            {isBackgroundLoading && <Spin spinning={true} />}
            {!isBackgroundLoading && (
              <>
                <img
                  alt=""
                  src={AvatarIcon}
                  style={{
                    objectFit: 'cover',
                    marginTop: '4px',
                    marginBottom: '-8px'
                  }}
                  height="80px"
                  width="80px"
                />
                <span
                  style={{
                    color: background ? 'white' : ' #136cfb',
                    backgroundColor: background ? '#136cfb' : 'none'
                  }}
                >
                  {mazeAvatar ? 'Avatar added' : 'Select Avatar'}
                </span>
              </>
            )}
          </div>
          <br />
          <div
            className={`maze_action column ${background ? '' : 'div_disabled'}`}
            onClick={() => (background ? onAddVoice() : null)}
            style={{
              borderColor: voiceError ? 'red' : '#d7d4d4'
            }}
          >
            {audio ? (
              <SoundOutlined style={{ color: '#136cfb', fontSize: '30px' }} />
            ) : (
              <img alt="" src={SoundIcon} />
            )}
            <span style={{ textAlign: 'center' }}>
              {audio ? `Audio added \n (${audio.toUpperCase()})` : 'Add audio'}
            </span>
          </div>
        </>
      )
    }
  }

  return (
    <div className="maze_right">
      <div className="maze_right_bottom">
        <div
          className="maze_action column"
          style={{
            backgroundImage: background ? `url('${background.link}')` : 'none',
            borderColor: backgroundError ? 'red' : '#d7d4d4'
          }}
          onClick={() => onAddBackground()}
        >
          {isBackgroundLoading && <Spin spinning={true} />}
          {!isBackgroundLoading && (
            <>
              <img alt="" src={GalleryIcon} style={{ objectFit: 'cover' }} />
              <span
                style={{
                  color: background ? 'white' : ' #136cfb',
                  backgroundColor: background ? '#136cfb' : 'none'
                }}
              >
                {background ? 'Background added' : 'Add Background'}
              </span>
            </>
          )}
        </div>
        <br />
        <Row gutter={16} align="middle" style={{ marginBottom: '16px' }}>
          <Col span={8}>
            <label style={{ fontSize: '16px', lineHeight: '32px' }}>
              Voice Type
            </label>
          </Col>
          <Col span={16}>
            <Select
              placeholder="Select Voice Type"
              onChange={handleVoiceTypeChange}
              value={voice_type}
              style={{ width: '100%' }}
            >
              <Option value="traditional">Sharelook Standard</Option>
              {isELEnabled && (
                <Option value="elevenlabs">Sharelook Premium</Option>
              )}
              {isELClonedEnabled && (
                <Option value="elevenlabs_clone_voice">
                  Sharelook Custom (Voice Cloning)
                </Option>
              )}
            </Select>
          </Col>
        </Row>
        {renderControls()}
      </div>
    </div>
  )
}

export default MazeRightPanel
