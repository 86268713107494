import React from 'react'
import { Form, Radio, Select, Row, Col, Card } from 'antd'

const { Option } = Select

const ImageTextForm = ({ form, includeText, isImageBeingGenerated }) => {
  const { getFieldDecorator } = form

  const textPositions = [
    { label: 'Top Left', key: 'top-left' },
    { label: 'Top Center', key: 'top-center' },
    { label: 'Top Right', key: 'top-right' },
    { label: 'Center', key: 'center' },
    { label: 'Bottom Left', key: 'bottom-left' },
    { label: 'Bottom Center', key: 'bottom-center' },
    { label: 'Bottom Right', key: 'bottom-right' }
  ]

  const fontSizes = [
    { value: 'big', label: 'Big' },
    { value: 'medium', label: 'Medium' },
    { value: 'small', label: 'Small' }
  ]

  const fontTypes = [
    'Retro',
    'Techy',
    'Neon',
    'Sketch',
    '3D',
    'Comic Style',
    'Anime Style',
    'Illustration',
    'Hand Written',
    'Quirky',
    'Chalk Board'
  ]

  const fontStyles = [
    { value: 'regular', label: 'Regular' },
    { value: 'bold', label: 'Bold' },
    { value: 'italic', label: 'Italic' },
    { value: 'thin', label: 'Thin' }
  ]

  const textColors = [
    'Vibrant',
    'Black & White',
    'Gold Script',
    'Rainbow Neon',
    'Gradient'
  ]

  return (
    <>
      {includeText && (
        <Card title="Text Options" style={{ marginBottom: 16 }}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                required={false}
                colon={false}
                label="📝 Text Position"
              >
                {getFieldDecorator('textPosition', {
                  initialValue: 'top-left',
                  rules: [
                    { required: true, message: 'Please select a text position' }
                  ]
                })(
                  <Radio.Group
                    buttonStyle="solid"
                    className="text-position-group"
                  >
                    {textPositions.map(pos => (
                      <Radio.Button key={pos.key} value={pos.key} disabled={isImageBeingGenerated}>
                        {pos.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item required={false} colon={false} label="Font Size">
                {getFieldDecorator('fontSize', {
                  initialValue: 'medium',
                  rules: [
                    { required: true, message: 'Please select a font size' }
                  ]
                })(
                  <Select disabled={isImageBeingGenerated}>
                    {fontSizes.map(size => (
                      <Option key={size.value} value={size.value}>
                        {size.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item required={false} colon={false} label="Font Style">
                {getFieldDecorator('fontStyle', {
                  initialValue: 'regular',
                  rules: [
                    { required: true, message: 'Please select a font style' }
                  ]
                })(
                  <Select disabled={isImageBeingGenerated}>
                    {fontStyles.map(style => (
                      <Option key={style.value} value={style.value}>
                        {style.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item required={false} colon={false} label="Font Type">
                {getFieldDecorator('fontType', {
                  initialValue: 'retro',
                  rules: [
                    { required: true, message: 'Please select a font type' }
                  ]
                })(
                  <Select disabled={isImageBeingGenerated}>
                    {fontTypes.map(type => (
                      <Option key={type} value={type.toLowerCase()}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item required={false} colon={false} label="Text Color">
                {getFieldDecorator('textColor', {
                  initialValue: 'vibrant',
                  rules: [
                    { required: true, message: 'Please select a text color' }
                  ]
                })(
                  <Select disabled={isImageBeingGenerated}>
                    {textColors.map(color => (
                      <Option key={color} value={color.toLowerCase()}>
                        {color}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Card>
      )}

      <p className="image-generation-note">
        * Please note that AI-generated content may not always be accurate. Text
        placement and formatting, such as the addition of text, may sometimes be
        imprecise.
      </p>
      <Form.Item label="📝 Number of images">
        {getFieldDecorator('numImages', {
          initialValue: '1',
          rules: [{ required: true, message: 'Please select number of images' }]
        })(
          <Radio.Group buttonStyle="solid" className="text-position-group">
            {[1, 2, 3, 4].map(num => (
              <Radio.Button key={num} value={num.toString()} disabled={isImageBeingGenerated}>
                {num}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    </>
  )
}

export default ImageTextForm
