import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import {
  Input,
  Button,
  Select,
  Form,
  Progress,
  Tooltip,
  Divider,
  Spin,
  Popconfirm,
  Radio,
  Checkbox,
  Card,
  message
} from 'antd'
import 'antd/dist/antd.css'
import { config } from '../../../../../../../../../constants'
import './AiImages.scss'
import { ImagePreview } from '../../../../../../../../../components'
import { api } from '../../../../../../../../../services'
import sendMessageIcon from '../../images/send.png'
import dummyUserImg from '../../../../../../../../../assets/user.svg'
import { useSelector } from 'react-redux'
import ImageCarousel from './ImageCarousel'
import ImageTextForm from './ImageTextForm'

const { Option } = Select
const AiImages = ({
  form,
  onImage,
  selectedImage,
  isImageBuilder,
  isBackground = false
}) => {
  const { getFieldDecorator } = form
  console.log({ isBackground })

  const [promptText, setPromptText] = useState('')
  const [promptTitle, setPromptTitle] = useState('')
  const [includeText, setIncludeText] = useState(false)
  const [generationStatus, setGenerationStatus] = useState({
    status: 'idle',
    percentage: 0,
    isVariation: false
  })
  const [generatedImageArray, setGeneratedImageArray] = useState([])
  const [selectedPosition, setSelectedPosition] = useState(1)
  const [upscaledImage, setUpscaledImage] = useState('')
  const [currentGeneratedImage, setCurrentGeneratedImage] = useState({
    info: '',
    url: ''
  })
  const endOfPagesRef = useRef(null)

  let ws

  useEffect(() => {
    const cleanup = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close()
        console.log('WebSocket closed.')
      }
    }

    return cleanup
  }, [ws])

  useEffect(() => {
    if (
      generationStatus.status === 'waiting-to-start' ||
      generationStatus.status === 'running' ||
      generationStatus.status === 'complete'
    ) {
      goToEndOfPages()
    }
  }, [generationStatus.status])

  const goToEndOfPages = () => {
    if (endOfPagesRef.current) {
      endOfPagesRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const handleGenerateImage = (prompt = '') => {
    if (prompt) {
      startGeneration(prompt)
    } else {
      form.validateFields(async (errors, values) => {
        if (errors || !promptText) {
          return
        }

        startGeneration()
      })
    }
  }

  const startGeneration = value => {
    let prompt = value || promptText
    if (promptTitle) {
      prompt = `${prompt} with text "${promptTitle}"`
    }
    if (!isImageBuilder) {
      generateWithIdeoGram(value || promptText)
      return
    }
    setGenerationStatus({ status: 'waiting-to-start', percentage: 0 })
    setCurrentGeneratedImage({ info: '', url: '' })

    api.imageGeneration
      .generateImage({
        prompt: prompt
      })
      .then(response => {
        setupWebsocket(response.data.MessageId)
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', percentage: 0 })
      })
  }

  const generateWithIdeoGram = (prompt, negativePrompt = '') => {
    form.validateFields(async (errors, values) => {
      if (errors) return
      setGeneratedImageArray([])
      let fullPrompt = `Prompt: "${prompt ||
        values.prompt}". Aspect ratio: ASPECT_16_9. `
      let fullNegativePrompt = negativePrompt || values.negativePrompt || ''

      if (isBackground) {
        fullPrompt = `Create a subtle and non-distracting background image for a digital course, inspired by "${prompt ||
          values.prompt}". The image should incorporate elements of ${prompt ||
          values.prompt} in a very abstract, faded, or minimalist way. Use extremely soft colors, gentle gradients, or barely noticeable patterns that hint at the theme without being too literal or prominent. The overall effect should be light and airy, allowing for easy readability of overlaid text and content. The design must be suitable for a 16:9 aspect ratio and accommodate various UI elements. Ensure the elements are distributed evenly across the image to create a balanced, non-distracting backdrop.`

        // Add default negative prompts for backgrounds
        fullNegativePrompt +=
          ', text, words, letters, numbers, strong contrasts, vibrant colors, distinct shapes, logos, icons, realistic depictions, detailed illustrations, foreground elements'
      }

      if (includeText && !isBackground) {
        fullPrompt += `Text visible in image: "${values.title}". `
        fullPrompt += `Text position must be: "${values.textPosition}". `
        fullPrompt += `The text must be easily visible but not dominate the image. `
        fullPrompt += `Font size: ${values.fontSize}. `
        fullPrompt += `Font type: ${values.fontType}. `
        fullPrompt += `Font style: ${values.fontStyle}. `
        fullPrompt += `Text color: ${values.textColor}. `
      }

      const payload = {
        prompt: fullPrompt,
        negative_prompt: fullNegativePrompt,
        aspect_ratio: 'ASPECT_16_9',
        model: 'V_2',
        magic_prompt_option: 'ON'
      }
      setGenerationStatus({ status: 'running', percentage: '0' })

      handleGeneration(payload, values)
    })
  }

  const handleGeneration = async (payload, values) => {
    const { numImages } = values
    let completedImages = 0

    const updateProgress = () => {
      completedImages++
      const percentage = (completedImages / numImages) * 100
      setGenerationStatus({
        status: parseInt(completedImages) === parseInt(numImages) ? 'complete' : 'running',
        percentage: percentage.toFixed(0)
      })
    }

    try {
      for (let i = 0; i < numImages; i++) {
        const response = await api.imageGeneration.generateIdeogramImage(
          payload
        )

        if (!response || !response.data || !response.data.data) {
          throw new Error('No image data received')
        }

        const data = response.data.data
        if (data && data.length > 0 && data[0]) {
          const imageObject = {
            link: data[0].url,
            prompt: data[0].prompt,
            resolution: data[0].resolution,
            isImageSafe: data[0].is_image_safe,
            seed: data[0].seed,
            title: values.text,
            textPosition: values.textPosition,
            aspectRatio: values.aspectRatio
          }
          setGeneratedImageArray(prevState => [...prevState, imageObject])

          updateProgress()
        } else {
          throw new Error('No image data received')
        }
      }
    } catch (error) {
      console.error('Error:', error)
      setGenerationStatus({
        status: 'error',
        percentage: 0
      })
      message.error('Failed to generate image. Please try again.')
    }
  }

  const setupWebsocket = (messageId, isVariation = false) => {
    const socketUrl =
      config.midJourneySocket +
      (isVariation
        ? `?channel_type=SEND_VARIATION&channel_id=SEND-VARIATION-MESSAGEID-${messageId}`
        : `?channel_type=SEND_IMAGINE&channel_id=SEND-IMAGINE-MESSAGEID-${messageId}`)

    ws = new WebSocket(socketUrl)

    ws.onopen = () => {
      console.log('Websocket connected')
    }

    ws.onclose = () => {
      console.log('Websocket closed')
    }

    ws.onmessage = event => {
      const data = JSON.parse(event.data)
      const isGenerating =
        data.event === 'sendImagine' || data.event === 'sendVariation'
      if (isGenerating && data.progress === 'done') {
        setGenerationStatus({
          status: 'complete',
          percentage: 100,
          isVariation: data.event === 'sendVariation'
        })
        setCurrentGeneratedImage({ info: data.msg, url: data.msg.uri })
        ws.close()
      } else if (isGenerating) {
        setGenerationStatus({
          status: 'running',
          percentage: parseInt(data.progress.replace(/\D/g, ''), 10),
          isVariation: data.event === 'sendVariation'
        })
        setCurrentGeneratedImage({ info: '', url: data.url })
      }
    }
  }

  const generateVariations = () => {
    setGenerationStatus({
      status: 'waiting-to-start',
      percentage: 0,
      isVariation: true
    })

    api.imageGeneration
      .generateVariation({
        index: selectedPosition,
        msgId: currentGeneratedImage.info.id,
        hash: currentGeneratedImage.info.hash
      })
      .then(response => {
        setupWebsocket(response.data.MessageId, true)
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', percentage: 0 })
      })
  }

  const upscaleImage = () => {
    setGenerationStatus({ status: 'upscalingImage', percentage: 0 })
    const data = {
      msgId: currentGeneratedImage.info.id,
      hash: currentGeneratedImage.info.hash,
      index: selectedPosition
    }

    api.imageGeneration
      .upscaleImage(data)
      .then(response => {
        const upscaledImageURL = response.data.uri
        setUpscaledImage(upscaledImageURL)
        setGenerationStatus({ status: 'complete', percentage: 100 })
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', percentage: 0 })
      })
  }

  const handleOperationClick = operation => {
    if (operation === 'generate') {
      generateVariations()
    } else if (operation === 'upscale') {
      upscaleImage()
    }
  }

  const selectImage = (imageObj = {}) => {
    if (
      isImageBuilder &&
      selectedImage &&
      selectedImage.link === upscaledImage
    ) {
      return
    }

    onImage({
      link: isImageBuilder ? upscaledImage : imageObj.link,
      id: generate7DigitRandomInteger()
    })
  }

  function generate7DigitRandomInteger() {
    const min = 1000000 // Minimum value (inclusive)
    const max = 9999999 // Maximum value (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const isImageBeingGenerated = useMemo(() => {
    return (
      generationStatus.status !== 'idle' &&
      generationStatus.status !== 'error' &&
      generationStatus.status !== 'complete'
    )
  }, [generationStatus])
  const handleGenerateFromAi = prompt => {
    setPromptText(prompt)
    //scroll to div with id of promptInput

    const promptInput = document.getElementById('promptInput')
    if (promptInput) {
      promptInput.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="ai-images-container">
      <div className="ai-images-user-input">
        <AiImagesForm
          form={form}
          getFieldDecorator={getFieldDecorator}
          promptText={promptText}
          isImageBeingGenerated={isImageBeingGenerated}
          handleGenerateImage={handleGenerateImage}
          setPromptText={setPromptText}
          promptTitle={promptTitle}
          setPromptTitle={setPromptTitle}
          generationStatus={generationStatus}
          isImageBuilder={isImageBuilder}
          includeText={includeText}
          setIncludeText={setIncludeText}
          isBackground={isBackground}
        />
        <AiChat
          generateImage={handleGenerateFromAi}
          isImageBeingGenerated={isImageBeingGenerated}
        />
      </div>

      {generationStatus.status !== 'idle' && (
        <div className="ai-images-result">
          {currentGeneratedImage.url && (
            <div className="image-preview">
              <h3>{promptText}</h3>
              <ImagePreview
                src={currentGeneratedImage.url}
                alt="Generated Image"
              />
            </div>
          )}

          {generationStatus.status === 'complete' && isImageBuilder && (
            <div className="image-preview-actions">
              <Form.Item
                label="Select Position of Image You Like"
                name="position"
              >
                <Select
                  value={selectedPosition}
                  onChange={value => setSelectedPosition(value)}
                >
                  <Option value={1}>Top Left</Option>
                  <Option value={2}>Top Right</Option>
                  <Option value={3}>Bottom Left</Option>
                  <Option value={4}>Bottom Right</Option>
                </Select>
              </Form.Item>
              {currentGeneratedImage.info && (
                <div className="image-preview-actions-buttons">
                  <ButtonWithTooltip
                    type="primary"
                    showTooltip={!selectedPosition}
                    tooltip="Select a position of image you like to generate its variations"
                    onButtonClick={() => handleOperationClick('generate')}
                    buttonText="Generate variations"
                  />
                  <ButtonWithTooltip
                    type="primary"
                    showTooltip={!selectedPosition}
                    tooltip="Select a position of image you like to upscale it"
                    onButtonClick={() => handleOperationClick('upscale')}
                    buttonText="Upscale Image For Selection"
                  />
                </div>
              )}
            </div>
          )}

          {generationStatus.status === 'running' && (
            <div className="image-generation-status">
              <Progress percent={parseFloat(generationStatus.percentage)} />
              <p>
                {generationStatus.isVariation
                  ? 'Generating variations...'
                  : 'Generating your image...'}
              </p>
            </div>
          )}

          {generationStatus.status === 'waiting-to-start' && (
            <Spin
              // indicator={antIcon}
              tip={`Waiting to start generating ${generationStatus.isVariation ? 'variations' : 'image'
                }.`}
            />
          )}

          {generationStatus.status === 'upscalingImage' && (
            <Spin tip={'Upscaling image...'} />
          )}

          {generationStatus.status === 'error' && <p>Error generating.</p>}
          {upscaledImage && !isImageBeingGenerated && isImageBuilder && (
            <>
              <div className="image-preview">
                <h2>
                  {isImageBuilder ? 'Upscaled Image' : 'AI Generated Image'}
                </h2>
                <ImagePreview src={upscaledImage} alt="Upscaled Image" />
              </div>

              <Button
                type={
                  selectedImage && selectedImage.link === upscaledImage
                    ? 'default'
                    : 'primary'
                }
                onClick={selectImage}
              >
                {selectedImage && selectedImage.link === upscaledImage
                  ? 'Selected'
                  : 'Select'}
              </Button>
            </>
          )}

          {!isImageBeingGenerated && !isImageBuilder && (
            <ImageCarousel
              images={generatedImageArray}
              onSelectImage={selectImage}
            />
          )}
        </div>
      )}
      <div ref={endOfPagesRef} />
    </div>
  )
}

const ButtonWithTooltip = ({
  tooltip,
  onButtonClick,
  buttonText,
  showTooltip,
  ...props
}) => {
  if (showTooltip) {
    return (
      <Tooltip title={tooltip}>
        <Button disabled {...props}>
          {buttonText}
        </Button>
      </Tooltip>
    )
  }
  return (
    <Button onClick={onButtonClick} {...props}>
      {buttonText}
    </Button>
  )
}

const AiImagesForm = ({
  form,
  getFieldDecorator,
  promptText,
  isImageBeingGenerated,
  handleGenerateImage,
  setPromptText,
  promptTitle,
  setPromptTitle,
  generationStatus,
  isImageBuilder,
  includeText,
  setIncludeText,
  isBackground
}) => {
  const showIncludeText = isBackground ? false : true
  return (
    <Form
      className="ai-images-form"
      onFinish={() => handleGenerateImage()}
      layout="vertical"
    >
      <div className="ai-images-form-prompt" id="promptInput">
        <h1>⚡ AI Image Generation</h1>
        <Divider style={{ color: '#000' }}>
          Generate image using your own image idea
        </Divider>
      </div>
      <Form.Item name="prompt">
        {getFieldDecorator('prompt', {
          initialValue: promptText,
          rules: [
            {
              required: true,
              message: 'Please enter your image idea...'
            }
          ]
        })(
          <Input.TextArea
            disabled={isImageBeingGenerated}
            placeholder="Your prompt here..."
            value={promptText}
            autoSize={{ minRows: 2, maxRows: 5 }}
            onChange={e => setPromptText(e.target.value)}
          />
        )}
      </Form.Item>{' '}
      {showIncludeText && (
        <Form.Item required={false} colon={false} className="custom-form-item">
          {getFieldDecorator('includeText', {
            valuePropName: 'checked',
            initialValue: includeText
          })(
            <Checkbox
              disabled={isImageBeingGenerated}
              onChange={e => setIncludeText(e.target.checked)}
              className="include-text"
            >
              Add text in the image
            </Checkbox>
          )}
        </Form.Item>
      )}
      {includeText && (
        <Form.Item name="title" label="📝 Text">
          {getFieldDecorator('title', {
            initialValue: promptTitle,
            rules: [
              {
                required: !isImageBuilder,
                message: 'Please enter text to appear in the image'
              }
            ]
          })(
            <Input
              disabled={isImageBeingGenerated}
              value={promptTitle}
              size="large"
              placeholder="Enter the text to appear in the image"
              onChange={e => setPromptTitle(e.target.value)}
            />
          )}
        </Form.Item>
      )}
      {!isImageBuilder && (
        <ImageTextForm form={form} includeText={includeText} isImageBeingGenerated={isImageBeingGenerated} />
      )}
      <Form.Item>
        <Button
          onClick={() => handleGenerateImage()}
          disabled={
            isImageBuilder
              ? isImageBeingGenerated && generationStatus.status !== 'error'
              : false
          }
          type="primary"
          size="large"
          loading={isImageBeingGenerated}
        >
          Generate Image
        </Button>
      </Form.Item>
    </Form>
  )
}

const AiChat = ({ generateImage, isImageBeingGenerated }) => {
  const defaultMessages = [
    {
      user: false,
      message:
        "What type of image would you like to generate? Please describe the scene, objects, or any specific details you have in mind, and I'll do my best to create it for you."
    }
  ]

  const [isChatStarted, setIsChatStarted] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [messages, setMessages] = useState(defaultMessages)
  const [isReceivingMessage, setIsReceivingMessage] = useState(false)
  const [context, setContext] = useState([])
  const [revisedPrompt, setRevisedPrompt] = useState('')

  const messagesEndRef = useRef(null)

  const handleSubmit = () => {
    if (!userInput) return
    const data = {
      params: {
        prompt: userInput,
        context: context
      }
    }
    setMessages([...messages, { user: true, message: userInput }])
    setUserInput('')
    setIsReceivingMessage(true)
    api.imageGeneration.conversationAI(data).then(response => {
      const body = response.data.body
      setMessages(prevMsgs => [
        ...prevMsgs,
        { user: false, message: body && body[0].response }
      ])
      setContext(body && body[0].context)
      setRevisedPrompt(body && body[0].revised_prompt)
      setIsReceivingMessage(false)
    })
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  useEffect(() => {
    if (userInput && !isReceivingMessage) return
    scrollToBottom()
  }, [messages, userInput, isReceivingMessage])

  const handleStartNewChat = () => {
    setIsChatStarted(true)
    setMessages(defaultMessages)
    setContext([])
    setRevisedPrompt('')
  }

  const user = useSelector(state => state.user)

  const userImg =
    (user && user.info && user.info.avatar && user.info.avatar.link) ||
    dummyUserImg
  const renderChat = () => {
    return (
      <div className="ai-midjourney-chat">
        <div className="ai-midjourney-chat-messages">
          {messages.map((message, index) => (
            <div
              className={`ai-midjourney-chat-message ${message.user ? 'ai-midjourney-chat-message--user' : ''
                }`}
              key={index}
            >
              <div className="ai-midjourney-chat-message-avatar">
                {message.user ? <img src={userImg} alt="user" /> : 'AI'}
              </div>
              <p>{message.message}</p>
            </div>
          ))}

          {revisedPrompt && !isReceivingMessage && (
            <div className="ai-midjourney-chat-message ai-midjourney-chat-message--revised">
              <Button
                onClick={handleChatButton}
                disabled={isImageBeingGenerated}
              >
                Generate Image Using Suggested Prompt
              </Button>
              <Popconfirm
                title="Are you sure you want to start a new chat?"
                okText="Yes"
                cancelText="No"
                icon={null}
                onConfirm={handleStartNewChat}
              >
                <div className="ai-midjourney-chat-start-new">
                  Start New Chat
                </div>
              </Popconfirm>
            </div>
          )}

          {isReceivingMessage && (
            <div className={`ai-midjourney-chat-message`}>
              <div className="ai-midjourney-chat-message-avatar">AI</div>
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>

        <Form
          className="ai-midjourney-chat-input"
          onFinish={() => handleSubmit()}
        >
          <Input
            placeholder="Type a message"
            className="ai-midjourney-chat-input"
            value={userInput}
            onChange={e => setUserInput(e.target.value)}
            onPressEnter={() => handleSubmit()}
            disabled={isReceivingMessage || isImageBeingGenerated}
            suffix={
              <Tooltip title="Send">
                <img
                  className="ai-midjourney-chat-input-send"
                  onClick={() => {
                    console.log('Image clicked!') // Add this line for debugging
                    if (isReceivingMessage || isImageBeingGenerated) return
                    handleSubmit()
                  }}
                  src={sendMessageIcon}
                  style={{ opacity: userInput ? 1 : 0.3 }}
                  alt="send message"
                />
              </Tooltip>
            }
          />
        </Form>
      </div>
    )
  }

  const handleChatButton = () => {
    generateImage(revisedPrompt)

    setIsChatStarted(!isChatStarted)
  }

  return (
    <div className="ai-midjourney-chat-container">
      <p>Talk to AI about the image you want to generate</p>

      {isChatStarted && renderChat()}

      <Button
        disabled={isImageBeingGenerated}
        onClick={() => {
          setIsChatStarted(!isChatStarted)
          if (!isChatStarted) {
            handleStartNewChat()
          }
        }}
        type="primary"
        ghost
      >
        {isChatStarted ? 'Stop Chat' : 'Start Chat'}
      </Button>
    </div>
  )
}

export default Form.create()(AiImages)
