import React, { useState, useEffect } from 'react'
import { Progress, Row, Col } from 'antd'
// import { quotes } from '../assets/quotes'

// const Quotes = ({ percentageLoader }) => {
//   const [textIdx, setTextIdx] = useState(0)

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       let randomNumber = Math.floor(Math.random() * quotes.length)
//       setTextIdx(randomNumber)
//     }, 3500)

//     return () => clearInterval(intervalId) // Cleanup on unmount
//   }, [])

//   const myQuote = quotes[textIdx]

//   const showPercentageLoader = percentageLoader !== undefined

//   return (
//     <>
//       "{myQuote.text}"
//       <br />- {myQuote.by}
//       <br />
//       <br />
//       {showPercentageLoader && (
//         <Row>
//           <Col span={10} offset={8}>
//             <Progress percent={parseInt(percentageLoader, 10)} />
//           </Col>
//         </Row>
//       )}
//     </>
//   )

const PercentageLoader = (percentageLoader) => {
  return (
    <>
      <Row>
        <Col span={10} offset={8}>
          <Progress percent={percentageLoader * 100} />
        </Col>
      </Row>
    </>
  )
}

export default PercentageLoader
