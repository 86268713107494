import { Alert, Card as AntdCard, Col, Pagination, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Card } from '../../../../../components'
import { api } from '../../../../../services'
import TrainingListActions from '../components/TrainingListActions'
import TrainingListContainer from '../components/TrainingListContainer'
import { types } from '../types'
import { Container, Draggable } from 'react-smooth-dnd'
import TrainingSequence from '../components/TrainningSquence'
import { useTranslation } from 'react-i18next'

const PAGE_LIMIT = 12

function ChannelList(props) {
  const [channels, setChannels] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [search, setSearch] = useState('')
  const [pageOffset, setPageOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [sequenceState, setSequenceState] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [seqList, setSeqList] = useState([])
  const [newSequence, setNewSequence] = useState([])
  useEffect(() => {
    const fetchMazeData = () => {
      if (!loading) setLoading(true)
      api.channel
        .listChannels({
          offset: pageOffset,
          limit: PAGE_LIMIT,
          mode: 'training',
          search
        })
        .then(res => {
          setChannels(res.data)
          if (isInitialLoad) {
            setSeqList(res.data.slice(0, 10))
            setIsInitialLoad(false)
          }
          setTotal(res.data.pagination || 0)
          setLoading(false)
          setError(false)
        })
        .catch(err => {
          setTotal(0)
          setChannels([])
          setLoading(false)
          setError(true)
        })
    }
    fetchMazeData()
  }, [pageOffset, search])

  const handlePagination = pageNumber =>
    setPageOffset(pageNumber * PAGE_LIMIT - PAGE_LIMIT)

  const handleSearch = searchValue => {
    setPageOffset(0)
    setSearch(searchValue)
  }
  const onCardClick = ({ id }) => props.history.push(`/channel/${id}`)

  const currentPageNumber = useMemo(() => pageOffset / 10 + 1, [pageOffset])

  const showItems = !loading && channels.length > 0
  const handleSaveSequence = () => {
    if (newSequence.length > 0) {
      console.log('test save', newSequence)
    }
    setSequenceState(false)
  }
  const { t } = useTranslation()
  return (
    <TrainingListContainer title={t('v4:channel')}>
      <TrainingListActions
        type={types.CHANNEL}
        loading={loading}
        onListSearch={handleSearch}
        sequenceState={sequenceState}
        handleSaveSequence={handleSaveSequence}
        openSequenceState={val => {
          setSequenceState(val)
        }}
      />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={sequenceState ? 16 : 24}>
          {showItems && (
            <div className="training-list-pagination">
              <h2>
                {search && (
                  <>
                    {t('v4:search_result')} <u>{search}</u> -
                  </>
                )}{' '}
                {channels.length} {channels.length <= 1 ? t('v2:item') : t('v3:items')}
              </h2>
            </div>
          )}
          {loading ? (
            <div className="training-list-cards">
              {Array.from(Array(PAGE_LIMIT).keys()).map(item => (
                <AntdCard style={{ width: 250 }} loading>
                  {t('v4:this_card')}
                </AntdCard>
              ))}
            </div>
          ) : error ? (
            <Alert type="error" message="Error !" />
          ) : channels.length === 0 ? (
            <Alert
              message={
                search
                  ? `${t('v4:search_for')} '${search}' ${t(
                    'v4:search_no_result'
                  )}`
                  : `${t('v4:channel_no_available')}`
              }
            />
          ) : (
            <div className="training-list-cards">
              {channels.map((item, index) => (
                <Container
                  key={index}
                  groupName="1"
                  behaviour="copy"
                  getChildPayload={() => item}
                >
                  <Draggable payload={item}>
                    <Card
                      type={types.CHANNEL}
                      mode="channel"
                      data={item}
                      onClick={onCardClick}
                      tagName="channel"
                    />
                  </Draggable>
                </Container>
              ))}
            </div>
          )}
          <div className="pagination-wrapper">
            {showItems && (
              <Pagination
                disabled={loading}
                total={total}
                pageSize={PAGE_LIMIT}
                onChange={handlePagination}
                defaultCurrent={currentPageNumber}
              />
            )}
          </div>
        </Col>
        {sequenceState && (
          <Col xs={24} sm={24} md={8}>
            <TrainingSequence
              seqList={seqList}
              setNewSequence={setNewSequence}
              loading={loading}
              type={types.CHANNEL}
            />
          </Col>
        )}
      </Row>
    </TrainingListContainer>
  )
}

export default ChannelList
