/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-27 11:36:03
 * @LastEditors: Please set LastEditors
 */
import {
  HANDLE_LESSON_INPUT,
  LOAD_SINGLE_LESSON,
  RESET_LESSON_BUILDER,
  SET_SAVING_LESSON,
  FETCH_LESSON_REQUEST,
  HANDLE_FILE_DROP_IN,
  HANDLE_LESSON_CLONE
} from '../types'

const initialState = {
  lessonId: null,
  lessonTitle: '',
  lessonTags: [],
  lessonTopicList: [],
  lessonImgId: null,
  lessonImg: '',
  lessonDescription: '',
  is_allowed_clone: false,
  scenarioId: null,
  saving: false,
  fetch: false,
  droppedFile: null,
  pages: [],
  pages_landscape: [],
  currentPage: null,
  selectedLesson: null
}

const lessonBuilder = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LESSON_REQUEST:
      return {
        ...state,
        fetch: true
      }

    case HANDLE_FILE_DROP_IN:
      return {
        ...state,
        droppedFile: action.droppedFile
      }

    case HANDLE_LESSON_INPUT:
      console.log('check action.field', action.field)
      console.log('check action.payload', action.payload)
      return {
        ...state,
        [action.field]: action.payload
      }

    case HANDLE_LESSON_CLONE:
      return {
        ...state,
        selectedLesson: action.selectedLesson
      }

    case LOAD_SINGLE_LESSON:
      const { payload } = action
      console.log('single lesson payload', payload)
      return {
        ...state,
        lessonId: payload.id,
        lessonTitle: payload.title,
        lessonTags: payload.topics || [],
        lessonTopicList: payload.topic_list || [],
        lessonImgId: payload.cover && payload.cover.id,
        lessonImg: payload.cover && payload.cover.link,
        lessonImgName: payload.cover && payload.cover.filename,
        lessonImgSize:
          payload.cover && parseInt(payload.cover.file_size / 1024),
        lessonBckgrndImgId:
          payload.background_cover && payload.background_cover.id,
        lessonBckgrndColor: payload.background_color,
        lessonBckgrndImg:
          payload.background_cover && payload.background_cover.link,
        lessonDescription: payload.description,
        is_allowed_clone: payload.is_allowed_clone,
        scenarioId: payload.scenario && payload.scenario.id,
        lessonBckgrndImgName:
          payload.background_cover && payload.background_cover.filename,
        lessonBckgrndImgSize:
          payload.background_cover &&
          parseInt(payload.background_cover.file_size / 1024),

        pages: payload.pages.map(
          ({ data, size, orientation, uid, id, ordering }) => ({
            data,
            size,
            orientation,
            uid,
            id,
            ordering
          })
        ),
        pages_landscape: payload.pages_landscape.map(
          ({ data, size, orientation, uid, id, ordering }) => ({
            data,
            size,
            orientation,
            uid,
            id,
            ordering
          })
        ),
        fetch: false
      }

    case RESET_LESSON_BUILDER:
      return initialState

    case SET_SAVING_LESSON:
      return {
        ...state,
        saving: action.payload
      }

    default:
      return state
  }
}

export default lessonBuilder
