import Layout1 from '../img/layout-1.svg'
import Layout2 from '../img/layout-2.svg'
import Layout3 from '../img/layout-3.svg'
import Layout4 from '../img/layout-4.svg'
import Layout5 from '../img/layout-5.svg'
import Layout6 from '../img/layout-6.svg'
import Layout7 from '../img/layout-7.svg'
import Layout8 from '../img/layout-8.svg'
import Layout9 from '../img/layout-9.svg'
import Layout10 from '../img/layout-10.svg'
import Layout11 from '../img/layout-11.svg'
import Layout12 from '../img/layout-12.svg'
import Layout13 from '../img/layout-13.svg'
import Layout14 from '../img/layout-14.svg'
import Layout15 from '../img/layout-15.svg'

import nanoid from 'nanoid'
import _ from 'lodash'

const CONTAINER_WIDTH = 1023
const CONTAINER_HEIGHT = 552
const PADDING = 20
const GUTTER = 20

// Calculate dimensions for symmetrical layouts
const fullWidth = CONTAINER_WIDTH - 2 * PADDING // 983px
const halfWidth = (fullWidth - GUTTER) / 2 // 481.5px
const thirdWidth = (fullWidth - 2 * GUTTER) / 3 // 314.33px

// Helper function to create common element properties
const createElement = (type, display, params) => ({
  uid: nanoid(8),
  type,
  display: display || 'CONTENT',
  params
})

const baseHeading = createElement('TEXT', 'HEADING', {
  content: '',
  x: PADDING,
  y: 40,
  width: fullWidth,
  height: 58,
  media_format: 'TEXT'
})

// Helper function for text elements
const textElement = (content, x, y, width, height) =>
  createElement('TEXT', 'CONTENT', {
    content,
    x,
    y,
    width,
    height,
    media_format: 'TEXT'
  })

// Helper function for image elements
const imageElement = (imageData, x, y, width, height) =>
  createElement('IMAGE', null, {
    content: imageData,
    x,
    y,
    width,
    height,
    media_format: 'IMAGE'
  })

const logoImageElement = logoData => createElement('IMAGE', 'LOGO', logoData)

const createLayoutData = (
  textData,
  firstPart,
  secondPart,
  imageData,
  logoData
) => {
  console.log('createLayoutData', textData, firstPart, secondPart, imageData)
  return [
    {
      img: Layout13,
      layoutStyle: 'layout-13',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(textData, PADDING, 98, halfWidth, 454),
          imageElement(
            imageData,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            454
          )
        ]
      })
    },
    {
      img: Layout14,
      layoutStyle: 'layout-14',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          imageElement(imageData, PADDING, 98, halfWidth, 454),
          textElement(
            textData,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            454
          )
        ]
      })
    },
    {
      img: Layout15,
      layoutStyle: 'layout-15',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          imageElement(imageData, PADDING, 338, fullWidth, 214),
          textElement(textData, PADDING, 98, fullWidth, 200)
        ]
      })
    },
    {
      img: Layout4,
      layoutStyle: 'layout-4',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(textData, PADDING, 338, fullWidth, 214),
          imageElement(imageData, PADDING, 98, fullWidth, 200)
        ]
      })
    },
    {
      img: Layout3,
      layoutStyle: 'layout-3',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(firstPart, PADDING, 98, halfWidth, 454),
          textElement(
            secondPart,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            454
          )
        ]
      })
    },
    {
      img: Layout1,
      layoutStyle: 'layout-1',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(firstPart, PADDING, 338, halfWidth, 214),
          textElement(
            secondPart,
            PADDING + halfWidth + GUTTER,
            338,
            halfWidth,
            214
          ),
          imageElement(imageData, PADDING, 98, halfWidth, 200),
          imageElement('', PADDING + halfWidth + GUTTER, 98, halfWidth, 200)
        ]
      })
    },
    {
      img: Layout2,
      layoutStyle: 'layout-2',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(textData, PADDING, 338, fullWidth, 214),
          imageElement(imageData, PADDING, 98, thirdWidth, 200),
          imageElement('', PADDING + thirdWidth + GUTTER, 98, thirdWidth, 200),
          imageElement(
            '',
            PADDING + 2 * (thirdWidth + GUTTER),
            98,
            thirdWidth,
            200
          )
        ]
      })
    },
    {
      img: Layout5,
      layoutStyle: 'layout-5',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          imageElement(imageData, PADDING, 98, halfWidth, 200),
          imageElement('', PADDING + halfWidth + GUTTER, 98, halfWidth, 200),
          textElement(textData, PADDING, 338, fullWidth, 214)
        ]
      })
    },
    {
      img: Layout6,
      layoutStyle: 'layout-6',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(
            firstPart,
            PADDING,
            98,
            (fullWidth * 2) / 3 - GUTTER / 2,
            200
          ),
          imageElement(
            imageData,
            PADDING + (fullWidth * 2) / 3 + GUTTER / 2,
            98,
            fullWidth / 3,
            200
          ),
          textElement(secondPart, PADDING, 338, fullWidth, 214)
        ]
      })
    },
    {
      img: Layout7,
      layoutStyle: 'layout-7',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          createElement('ANNOTATION', null, {
            content: '',
            x: PADDING,
            y: 98,
            width: halfWidth,
            height: 200
          }),
          textElement(textData, PADDING, 338, halfWidth, 214),
          imageElement(
            imageData,
            imageData,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            454
          )
        ]
      })
    },
    {
      img: Layout8,
      layoutStyle: 'layout-8',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          createElement('VIDEO', null, {
            content: '',
            x: PADDING,
            y: 98,
            width: fullWidth,
            height: 360,
            media_format: 'VIDEO'
          }),
          textElement(textData, PADDING, 478, fullWidth, 74)
        ]
      })
    },
    {
      img: Layout9,
      layoutStyle: 'layout-9',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(textData, PADDING, 98, halfWidth, 454),
          imageElement(
            imageData,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            200
          ),
          imageElement('', PADDING + halfWidth + GUTTER, 338, halfWidth, 214)
        ]
      })
    },
    {
      img: Layout10,
      layoutStyle: 'layout-10',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(firstPart, PADDING, 98, halfWidth, 200),
          textElement(
            secondPart,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            200
          ),
          createElement('ANNOTATION', null, {
            content: '',
            x: PADDING,
            y: 338,
            width: halfWidth,
            height: 214
          }),
          createElement('ANNOTATION', null, {
            content: '',
            x: PADDING + halfWidth + GUTTER,
            y: 338,
            width: halfWidth,
            height: 214
          })
        ]
      })
    },
    {
      img: Layout11,
      layoutStyle: 'layout-11',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          textElement(textData, PADDING, 98, halfWidth, 454),
          imageElement(
            imageData,
            PADDING + halfWidth + GUTTER,
            98,
            halfWidth,
            454
          )
        ]
      })
    },
    {
      img: Layout12,
      layoutStyle: 'layout-12',
      generateLayout: () => ({
        data: [
          baseHeading,
          ...(_.isEmpty(logoData) ? [] : [logoImageElement(logoData)]),
          imageElement(imageData, PADDING, 98, fullWidth / 4, 200),
          textElement(
            textData,
            PADDING + fullWidth / 4 + GUTTER,
            98,
            (3 * fullWidth) / 4 - GUTTER,
            200
          ),
          imageElement('', PADDING, 338, fullWidth / 4, 214),
          textElement(
            textData,
            PADDING + fullWidth / 4 + GUTTER,
            338,
            (3 * fullWidth) / 4 - GUTTER,
            214
          )
        ]
      })
    }
  ]
}

export default createLayoutData
