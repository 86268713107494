import {
  HANDLE_COURSE_INPUT,
  CREATE_COURSE,
  UPDATE_COURSE,
  GET_LESSONS_IN_COURSE,
  DELETE_LESSON_FROM_COURSE,
  RESET_COURSE_BUILDER,
  SET_COURSE_CREATION,
  COURSE_BUILDER_REORDER_LESSONS
} from '../types'

import { api } from '../../services'
export const handleCourseInput = (field, payload) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: HANDLE_COURSE_INPUT,
      field,
      payload,
    });
    resolve();
  });
};


export const resetCourseBuilder = () => ({
  type: RESET_COURSE_BUILDER
})

export const setCourseCreation = payload => ({
  type: SET_COURSE_CREATION,
  payload
})

/**
 Sagas
 */
export const createCourse = (data) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: CREATE_COURSE,
      data
    });
    resolve();
  });
};
export const updateCourse = (data, id) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: UPDATE_COURSE,
      data,
      id
    });
    resolve();
  });
};

export const deleteLessonFromCourse = (courseId, lessonId) => ({
  type: DELETE_LESSON_FROM_COURSE,
  courseId,
  lessonId
})

export const getLessonsList = id => ({
  type: GET_LESSONS_IN_COURSE,
  id
})

export const update = (data, id) => {
  return dispatch => {
    return api.courses.courseUpdate(data, id)
  }
}

export const reorderLessons = array => {
  return dispatch => {
    dispatch({
      type: COURSE_BUILDER_REORDER_LESSONS,
      payload: array
    })
  }
}
